<template>
  <div>
    <v-toolbar class="rounded-t-lg border-primary" style="border-bottom:2px solid;box-shadow: none">
      <h2 class="font-weight-bold">{{ $t('pages.users') }}</h2>
      <v-spacer></v-spacer>
      <v-toolbar-items class="align-center justify-space-between">
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('common.search')"
            single-line
            @change="getList()"
            hide-details
        ></v-text-field>
      </v-toolbar-items>
    </v-toolbar>

    <v-data-table
        :options.sync="table.options"
        :headers="headers"
        :items="items"
        :items-per-page="table.pagination.defaultPageSize"
        :loading="table.loading"
        :server-items-length="table.total"
        class="rounded-b-lg"
        :footer-props="{
          itemsPerPageOptions: table.pagination.pageSizeOptions,
        }"
    >

      <template v-slot:item.actions="{  }">
        <div class="d-flex justify-space-between">
          <a class="open mr-2 text-decoration-underline">Activate</a>
          <a class="mr-2">
            <v-icon>mdi-pencil</v-icon>
          </a>
        </div>
        <!--        <a @click="remove(item.id)" class="closed pl-3">-->
        <!--          <font-awesome-icon :icon="['fa', 'times']"/>-->
        <!--        </a>-->
      </template>
    </v-data-table>

    <v-pagination
        v-model="table.options.page"
        :length="table.pages"
    >
    </v-pagination>
  </div>
</template>

<script>
import TablePaginationMixin from "@/mixins/tablePagination";

export default {
  name: "Users",
  data: () => ({
    search: '',
    items: []
  }),
  mixins: [TablePaginationMixin],
  watch: {
    'table.options': {
      handler() {
        this.getList()
      },
      deep: true,
    },
  },
  methods: {
    getList() {
      this.table.loading = true;
      this.$http.get('/admin/users/', {params: {...this.table.options, search: this.search}}).then(({data}) => {
        this.items = data.data;
        if (data.meta) {
          this.table.pages = data.meta.last_page;
          this.table.total = data.meta.total;
        }
      }).finally(() => {
        this.table.loading = false;
      });
    },
    // onPageChange() {
    //   this.getList();
    // },
  },

  mounted() {
  },

  computed: {
    headers() {
      return [
        {text: this.$t('users.id'), value: 'id', width: "65px",sortable: false},
        {text: this.$t('users.first_name'), value: 'first_name',sortable: false},
        {text: this.$t('users.last_name'), value: 'last_name',sortable: false},
        {text: this.$t('users.email'), value: 'email',sortable: false},
        {text: this.$t('users.phone'), value: 'phone',sortable: false},
        {text: this.$t('users.role'), value: 'role',sortable: false},
      ]
    },
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/scss/var"

.border-primary
  border-color: $cGreen !important

.open
  color: $cGreen !important

.closed
  color: $cRed !important

</style>
