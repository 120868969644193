<template>
  <section class="home__items">
    <div class="container py-8">

      <h1 class="text-center font-weight-bold mb-4">{{ $t('home.investment_opportunities') }}</h1>
      <div class="row">
        <div class="col-md-8 mx-auto">
          <p class="text-center">{{$t('home.investment_opportunities_text')}}</p>
        </div>
      </div>

      <v-row class="my-4">
        <v-col cols="12" md="6" v-for="item in items" :key="'opp-'+item.id">
          <opportunity-list-item :item="item" route_name="OpportunityView"></opportunity-list-item>
        </v-col>
      </v-row>
      <router-link :to="{name: 'Opportunities'}" class="d-block text-center primary--text-link text-decoration-underline">
        {{ $t('home.see_all') }}
      </router-link>
    </div>
  </section>
</template>

<script>
import OpportunityListItem from "@/view/pages/Opportunities/OpportunityListItem";

export default {
  name: "InvestmentOpportunities",
  components: {OpportunityListItem},
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.get('/opportunities/home').then(({data}) => {
        this.items = data.data;
      })
    }
  }
}
</script>

<style scoped>

</style>