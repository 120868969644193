<template>
  <div>
    <span class="d-inline-flex align-center"><v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
      {{ $t('profile.info') }}</span>
    <h2 class="font-weight-bold">{{ $t('profile.admin_title') }}</h2>
    <v-card class="mb-5">
      <v-card-text>
        <v-row>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.first_name"
                :label="$t('profile.first_name')"
                type="text"
                :error="hasError('user.first_name')"
                :error-messages="getErrors('user.first_name')"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.last_name"
                :error="hasError('user.last_name')"
                :error-messages="getErrors('user.last_name')"
                :label="$t('profile.last_name')"
                type="text"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.national_id"
                :label="$t('profile.national_id')"
                :error="hasError('user.national_id')"
                :error-messages="getErrors('user.national_id')"
                disabled
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-row dense>
              <v-col cols="10">
                <vue-tel-input-vuetify
                    v-model="form.user.phone"
                    :error="hasError('user.phone')"
                    :error-messages="getErrors('user.phone')"
                    :label="$t('profile.phone')"
                    type="phone"
                    defaultCountry="us"
                    mode="international"
                    outlined
                    disabled
                    append-icon="mdi-close"
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col cols="2">
                <v-btn color="primary" small class="custom_append" @click="openPhoneModal()">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.email"
                :label="$t('profile.email')"
                type="email"
                :error="hasError('user.email')"
                :error-messages="getErrors('user.email')"
                class="details_none"
                required
            ></v-text-field>
            <a href="#" v-if="getEmailVerified()" @click.prevent="resendVerification()">{{ getEmailVerified() }}</a>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.birthdate"
                :label="$t('profile.birthdate')"
                type="date"
                disabled
                :error="hasError('user.birthdate')"
                :error-messages="getErrors('user.birthdate')"
                required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-text>
        <h2 class="font-weight-bold mb-4">{{ $t('profile.user_acc_info') }}</h2>
        <v-row>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.account.password"
                :label="$t('profile.password')"
                type="password"
                autocomplete="new-password"
                :error="hasError('account.password')"
                :error-messages="getErrors('account.password')"
            ></v-text-field>
            <v-text-field
                outlined
                v-model="form.account.password_confirmation"
                :label="$t('auth.confirm_password')"
                autocomplete="new-password"
                type="password"
                :error="hasError('account.password_confirmation')"
                :error-messages="getErrors('account.password_confirmation')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="d-flex  align-center py-0">
      <a href="#" @click="submit" class="btn">
        {{ $t('common.save') }}
      </a>
      <router-link :to="{name:'Dashboard'}" href="#" class="btn btn__cancel ml-4">
        {{ $t('common.cancel') }}
      </router-link>
      <v-snackbar
          v-model="saved"
          :timeout="2000"
      >
        <template>
          {{ $t('common.saved') }}

        </template>
      </v-snackbar>
    </div>
    <phone-number-modal title="Change Phone Number" ref="phone_modal" @updated="getData()"></phone-number-modal>
  </div>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import PhoneNumberModal from "@/view/pages/Profile/Forms/PhoneNumberModal";

export default {
  name: "AdminForm",
  components: {PhoneNumberModal},
  mixins: [hasApiValidation],
  data() {
    return {
      saved: false,
      email_not_verified: false,
      form: {
        user: {
          national_id: '',
          birthdate: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
        },
        account: {
          password: '',
          photo: null,
        }
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    openPhoneModal() {
      this.$refs.phone_modal ? this.$refs.phone_modal.open() : null;
    },
    getData() {
      this.$http.get('/admin/profile').then(({data}) => {
        Object.assign(this.form.user, data.data.user);
        this.email_not_verified = !data.data.user.email_verified_at;
      })
    },
    submit() {
      this.setErrors({});
      // this.form.account.photo = this.user_photo ? this.user_photo.id : null;
      this.$http.post('/admin/profile', this.form).then(({data}) => {
        this.saved = true;
        this.email_not_verified = !data.data.user.email_verified_at;
        this.setErrors({});
      }).catch(({response}) => {
        this.setErrors(response.data.errors);
      });
    },
    getEmailVerified() {
      if (!this.email_not_verified) {
        return false;
      }
      return this.$t('profile.email_need_verify')
    },
    resendVerification() {
      this.$http.post('/auth/resend-verification', {email: this.form.user.email})
          .then(() => {
            this.$noty.success(this.$t('common.send'))
          }).catch(() => {
      })
    },
  }
}
</script>

<style scoped lang="scss">

</style>