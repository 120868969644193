/* Layout */
import OpportunityCreate from '@/modules/opportunities/OpportunityCreate'
import OpportunityEdit from '@/modules/opportunities/OpportunityEdit'
import AppOpportunities from "@/view/pages/Opportunities/AppOpportunities";
import View from "@/view/pages/Investor/Opportunities/View";
import OpportunityInvestors from "@/view/pages/Admin/OpportunityInvestors";

export default [
    {
        path: 'opportunities',
        component: {
            render(h) {
                return h('router-view');
            }
        },
        meta: {
            title: 'Opportunities',
            // icon: '',
            //roles: ['SuperAdmin'],
        },
        children: [
            {
                path: '',
                name: 'opportunity-list',
                meta: {
                    title: 'Opportunities List',
                    layout: 'Dashboard'
                    // icon: '',
                    //roles: ['SuperAdmin'],
                    //permissions: ['view_categories']
                },
                component: AppOpportunities
            },
            {
                path: ':id(\\d+)',
                name: 'ViewOpportunity',
                meta: {
                    title: 'Opportunity',
                    layout: 'Dashboard'
                    // icon: '',
                    //roles: ['SuperAdmin'],
                    //permissions: ['view_categories']
                },
                component: View,
                props: true,
            },
            {
                path: 'create',
                name: 'opportunity-create',
                meta: {
                    title: 'Opportunity Create ID:',
                    layout: 'Dashboard'
                    //roles: ['SuperAdmin'],
                    //permissions: ['edit_categories']
                },
                component: OpportunityCreate,
            },
            {
                path: 'edit/:id(\\d+)',
                name: 'opportunity-edit',
                meta: {
                    title: 'Opportunity Edit',
                    layout: 'Dashboard'
                    //roles: ['SuperAdmin'],
                    //permissions: ['edit_categories']
                },
                component: OpportunityEdit,
            },
            {
                path: ':id(\\d+)/investors',
                name: 'OpportunityInvestors',
                meta: {
                    title: 'Opportunity Investors',
                    layout: 'Dashboard'
                    //roles: ['SuperAdmin'],
                    //permissions: ['edit_categories']
                },
                props: true,
                component: OpportunityInvestors,
            },
        ]
    },
]
