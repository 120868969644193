<template>
  <section class="home__about relative">
    <div class="container py-8">
      <div class="about_bg">
      </div>
      <div class="row">
        <div class="col-md-6">
          <h1 class="font-weight-bold mb-4">{{$t('home.what_is_emtalk')}}</h1>
          <p v-for="(text,index) in $t('home.what_is_emtalks')" :key="'em-'+index">
            {{
              text
            }}
          </p>
          <a href="#" class="btn">{{ $t('home.sign_up') }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutSection"
}
</script>

<style scoped>

</style>