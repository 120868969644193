<template>
  <div class="payment_logos">
    <div class="visa"></div>
    <div class="master"></div>
    <div class="mada"></div>
  </div>
</template>

<script>
export default {
name: "PaymentLogos"
}
</script>

<style scoped>

</style>