<template>
  <div>
    <user-dashboard v-if="$auth.user() && $auth.user().role !== 'ADMIN'"></user-dashboard>
    <span v-else>Dashboard</span>
  </div>
</template>

<script>
import UserDashboard from "@/view/pages/Dashboard/UserDashboard";
export default {
  name: 'Dashboard',
  components: {UserDashboard}
}
</script>
<style lang="scss">

</style>
