import Home from '@/view/pages/Home'
import Dashboard from '@/view/pages/Dashboard/Dashboard'
//import NotFound from '@/pages/404'
//import Profile from '@/pages/Profile'
import {MAIN_ROUTE} from '@/core/config/const'
import OpportunityRoutes from './opportunities'
import PublicOpportunityRoutes from './opportunities/publicOpportunities'
import View from "@/view/pages/Profile/View";
import Invest from "@/view/pages/Investor/Opportunities/Invest";
import AddFunds from "@/view/pages/Wallet/AddFunds";
import TermsAndConditions from "@/view/pages/TermsAndConditions";
import PrivacyPolicy from "@/view/pages/PrivacyPolicy";
import Transactions from "@/view/pages/Wallet/Transactions";
import InvestResult from "@/view/pages/Investor/Opportunities/InvestResult";
import CompanySettings from "@/view/pages/Admin/CompanySettings";
import Users from "@/view/pages/Admin/Users/Users";
import ContactUs from "@/view/pages/ContactUs";

export default [
    {
        path: '/',
        name: MAIN_ROUTE,
        meta: {
            layout: 'Home'
        },
        component: Home
    },
    {
        path: '/terms',
        name: 'TermsAndConditions',
        meta: {
            layout: 'Home'
        },
        component: TermsAndConditions
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        meta: {
            layout: 'Home'
        },
        component: PrivacyPolicy
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        meta: {
            layout: 'Home'
        },
        component: ContactUs
    },
    ...PublicOpportunityRoutes,
    {
        path: '/app',
        meta: {
            auth: true,
            layout: 'Dashboard'
        },
        component: {
            render(h) {
                return h('router-view');
            }
        },
        children: [
            {
                name: 'Dashboard',
                path: '',
                meta: {
                    title: 'pages.dashboard',
                    layout: 'Dashboard'
                },
                component: Dashboard
            },
            {
                name: 'Profile',
                path: 'profile',
                meta: {
                    title: 'pages.profile',
                    layout: 'Dashboard'
                },
                component: View
            },
            {
                path: 'invest/:id',
                name: 'OpportunityInvest',
                meta: {
                    title: 'Invest',
                    layout: 'Dashboard'
                    // icon: '',
                    //roles: ['SuperAdmin'],
                    //permissions: ['view_categories']
                },
                component: Invest,
                props: route => ({...route.query, ...route.params}),
            },
            {
                path: 'invest/:id/result',
                name: 'OpportunityInvestResult',
                meta: {
                    title: 'InvestResult',
                    layout: 'Dashboard'
                    // icon: '',
                    //roles: ['SuperAdmin'],
                    //permissions: ['view_categories']
                },
                component: InvestResult,
                props: route => ({...route.query, ...route.params}),
            },
            {
                path: 'company-settings',
                name: 'CompanySettings',
                meta: {
                    title: 'CompanySettings',
                    layout: 'Dashboard'
                    // icon: '',
                    //roles: ['SuperAdmin'],
                    //permissions: ['view_categories']
                },
                component: CompanySettings,
            },
            {
                path: 'users',
                name: '',
                meta: {
                    title: 'Users',
                    layout: 'Dashboard'
                    // icon: '',
                    //roles: ['SuperAdmin'],
                    //permissions: ['view_categories']
                },
                component: {
                    render(h) {
                        return h('router-view');
                    }
                },
                children: [{
                    path: '',
                    name: 'Users',
                    component: Users,
                    meta: {
                        title: 'Users',
                        layout: 'Dashboard'
                    }
                }]
            },
            {
                name: '',
                path: 'wallet',
                meta: {
                    title: 'pages.wallet',
                    layout: 'Dashboard'
                },
                component: {
                    render(h) {
                        return h('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'Wallet',
                        component: AddFunds,
                        meta: {
                            layout: 'Dashboard'
                        },
                    },
                    {
                        path: 'transactions',
                        name: 'Transactions',
                        component: Transactions,
                        meta: {
                            layout: 'Dashboard',
                            title: 'pages.transactions'
                        },
                    },
                ]
            },
            ...OpportunityRoutes
        ]
    },


// , {
//     path: '/404',
//     name: 'not_found',
//     component: NotFound
// }, {
//     path: '/profile',
//     name: 'profile',
//     component: Profile
// }, {
//     path: '*',
//     component: NotFound
// }
]

// { path: '/lazy-load', component: () =>  import(/* webpackChunkName: "lazy-load" */ "./view/LazyLoad") },
