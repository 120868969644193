import Header from '@/components/base/Header/index'
import AppFooter from '@/components/base/AppFooter'
import Modal from '@/components/Modal'
import SignInForm from '@/modules/auth/components/Forms/SignInForm'
import SignUpForm from '@/modules/auth/components/Forms/SignUpForm'
import OneTimePassword from '@/modules/auth/components/Forms/OneTimePassword'
import NewPassword from '@/modules/auth/components/Forms/NewPassword'
// import AuthApiService from '@/modules/auth/api'

export default {
    data: () => ({
        sidebar: true,
        dialogSignIn: false,
        dialogSignUp: false,
        dialogOneTimePassword: false,
        dialogForgotPassword: false,
        dialogNewPassword: false,
        dialogVerifyYourEmail: false,
    }),

    components: {
        Header,
        AppFooter,
        Modal,
        SignInForm,
        SignUpForm,
        NewPassword,
        OneTimePassword
    },

    methods: {
        //Todo: get close event any modal and close others
        closeAll() {
            this.dialogSignIn = false;
            this.dialogSignUp = false;
            this.dialogOneTimePassword = false;
            this.dialogForgotPassword = false;
            this.dialogNewPassword = false;
            this.dialogVerifyYourEmail = false;
        },
    },

    mounted() {
    },

    created () {
    }
}
