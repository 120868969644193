export default {
    namespaced: true,

    state: {
        options: {},
    },

    mutations: {
        options (state, value) {
            state.options = value
        }
    },

    actions: {
        options ({ commit, state }, value) {
            if (this._vm.$isObject(value)) {
                commit('options', { ...state.options, ...value })
            }
        }
    },

    getters: {

    }
}
