<template>
  <div>
    <v-data-table
        :loading="loading"
        class="rounded-b-lg"
        :items="data"
        :headers="headers"
        :items-per-page="-1"
        hide-default-footer
    >
      <template v-slot:item.value="{ item }">
        <v-text-field type="text"
                      :placeholder="item.value"
                      :ref="'value_'+item.id"
                      append-icon="mdi-arrow-right-bold"
                      v-model="item.value"
                      @click:append="saveValue(item)"
                      :error="hasError(item.key)"
                      :error-messages="getErrors(item.key)"
        ></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "CompanySettings",
  mixins: [hasApiValidation],
  data() {
    return {
      loading: false,
      data: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http.get('admin/settings').then(({data}) => {
        this.data = data.data;
        this.loading = false;
      })
    },
    saveValue(item) {
      this.setErrors({});
      this.$http.put('admin/settings/' + item.key, {value: item.value}).then(() => {
        this.getData();
      }).catch(({response}) => {
        let custom = {}
        if (response.data.errors && response.data.errors.value) {
          custom[item.key] = response.data.errors.value;
        }
        this.setErrors(custom);
      })
    }
  },
  computed: {

    headers() {
      return [
        // {text: 'Opportunity ID', value: 'opportunity_id', width: "100px"},
        {text: 'ID', value: 'id', width: "85px", sortable: false},
        {text: 'Name', value: 'name', sortable: false},
        {text: 'Value', value: 'value', sortable: false},
      ]
    },
  }
}
</script>

<style scoped>

</style>