import apiRequest from '@/request'

export default {
    state: {
        opportunities: [],
    },

    mutations: {
        setOpportunities(state, value) {
            state.opportunities = value
        }
    },

    actions: {
        getAdminOpportunities({commit}, params) {
            return new Promise((resolve, reject) => {
                apiRequest.get('/app/opportunities', {params: params})
                    .then(({data}) => {
                        commit('setOpportunities', data.data)
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    })
            })
        },
        getOpportunities({commit}, params) {
            return new Promise((resolve, reject) => {
                apiRequest.get('/opportunities', {params: params})
                    .then(({data}) => {
                        commit('setOpportunities', data.data)
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    })
            })
        }
    },

    getters: {
        opportunities: (state) => state.opportunities
    }
}
