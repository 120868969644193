import Resend from '@/modules/auth/components/Helpers/ResendLink'

export default {
    data: () => ({
        form: {
            code: ''
        }
    }),

    props: {

    },

    components: {
        Resend
    },

    methods: {

    },

    created () {

    }
}
