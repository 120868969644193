<template>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="maxWidth + 'px'"
        @click:outside="outsideClose"
    >
      <v-card>
        <v-col>
          <v-card-title>
            <span class="text-h5 font-weight-bold">{{ title }}</span>
            <v-spacer></v-spacer>
            <font-awesome-icon @click="close()" :icon="['fa', 'times']"/>
          </v-card-title>
        </v-col>
        <v-card-text v-if="description">
          {{ description }}
        </v-card-text>
        <v-card-text>
          <span v-if="error_message" class="d-block mb-3">{{error_message}}</span>
          <v-form>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.password"
                    :label="$t('auth.new_password')"
                    :error="hasError('password')"
                    :error-messages="getErrors('password')"
                    type="password"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.password_confirmation"
                    :label="$t('auth.confirm_password')"
                    type="password"
                    required
                ></v-text-field>
              </v-col>
              <v-col class="py-0 pb-2">
                <a href="#" class="btn" @click.prevent="resetPass">
                  {{ $t('common.save') }}
                </a>
              </v-col>
            </v-row>
            <SignUpLink @sign-up="$emit('sign-up');close()"/>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
<script>

import hasApiValidation from "@/mixins/hasApiValidation";
import SignUpLink from "@/modules/auth/components/Helpers/SignUpLink/index";

export default {
  name: "ResetPasswordModal",
  data: () => ({
    dialog: false,
    form: {
      email: '',
      token: '',
      password: '',
      password_confirmation: ''
    },
    error_message:''
  }),

  components: {
    SignUpLink
  },

  mixins: [hasApiValidation],
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 800
    }
  },

  methods: {
    open(data) {
      this.dialog = true;
      this.form.email = data.email;
      this.form.token = data.token;
      this.$emit('open', data)
    },

    outsideClose() {
      if (!this.preventClose) {
        this.close();
      }
    },

    close(e) {
      this.dialog = false
      this.$emit('close', e)
    },

    resetPass() {
      this.error_message = '';
      this.$http.post('/auth/reset', this.form).then(({data}) => {
        this.send = data.message;
        this.setErrors({})
        this.close();
        this.$emit('login');
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
        this.error_message = response.data.message;
      })
    }
  },
}

</script>
