<template>
  <section class="home__why relative">
    <div class="container py-8">
      <h1 class="font-weight-bold mb-4 text-center">{{$t('home.why_we')}}</h1>
      <div class="row">
        <div class="col-lg-3 col-md-6" v-for="(text, index) in $t('home.why_marks')" :key="'why-'+index">
          <div class="d-flex flex-column align-center text-center why-card">
            <span class="why_icon">
              <i class="fas " :class="'fa-'+ico"  v-for="(ico, key) in text.icons" :key="'why-'+index+'-ico-'+key"></i>
            </span>
            <h4 class="font-weight-bold my-3">{{ text.title }}</h4>
            <p class="font-weight-medium mt-0" >{{ text.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhySection"
}
</script>

<style scoped>

</style>