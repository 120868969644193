
export default {
    data: () => ({

    }),

    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            default: null
        },
        preventClose: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: Number,
            default: 800
        }
    },

    methods: {
        open (e) {
            this.dialog = true;
            this.$emit('open', e)
        },

        outsideClose() {
          if (!this.preventClose) {
              this.close();
          }
        },

        close (e) {
            // this.dialog = false
            this.$emit('close', e)
        }
    },
}
