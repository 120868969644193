<template>
  <v-card style="height: 100%">
    <v-card-title class="font-weight-bold">
      {{ $t('dashboard.portfolio_percentage') }}
    </v-card-title>
    <v-card-text>
      <pie-chart :chartData="datacollection" :options="defaultOptions()"
                 style="max-height: 300px;"></pie-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import {PieChart} from 'vue-chart-3';

export default {
  name: "PortfolioPercentage",
  components: {PieChart},
  data() {
    return {
      total: 0,
      results: []
    }
  },
  mounted() {
  },

  methods: {
    load(data) {
      this.total = data.total;
      this.results = data.results;
    },
    getRandomColor() {
      let letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    defaultDatasets() {
      return [
        {
          label: 'Invests',
          backgroundColor: this.results.map(() => this.getRandomColor()),
          data: this.results.map(item => Number.parseFloat(item.value)),
          hoverOffset: 4
        }
      ];
    },
    labels() {
      return this.results.map(item => item.title);
    },
    defaultOptions() {
      return {
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              var indice = tooltipItem.index;
              return data.labels[indice] + ': ' + this.$options.filters.toCurrency(data.datasets[0].data[indice], false) + '';
            },
          }
        }
        // legend: {
        //   display: false
        // },
        // scales: {
        //   yAxes: [{
        //     ticks: {
        //       callback: function(value, ) {
        //         return '$' + value;
        //       }
        //     },
        //   }]
        // },
        // elements: {
        //   line: {
        //     borderWidth: 1
        //   },
        //   point: {
        //     radius: 4,
        //     hitRadius: 10,
        //     hoverRadius: 4
        //   }
        // }
      }
    }
  },
  computed: {
    datacollection() {
      return {
        labels: this.labels(),
        datasets: this.defaultDatasets(),
      }
    },
  }
}
</script>

<style scoped>
</style>