<template>
  <v-container v-if="$auth.user() && $auth.user().role !== 'ADMIN'">
    <div class="d-flex justify-space-between align-center">
      <h2 class="font-weight-bold">{{ $t('pages.add_funds') }}</h2>
      <router-link :to="{name:'Transactions'}" >{{ $t('wallet.view_transaction_history') }}</router-link>
    </div>
    <v-row>
      <v-col lg="7" cols="12">
        <div class="opportunity-card">
          <v-card>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <div class="mb-5">
                  <label class="c-gray d-block">{{ $t('wallet.current_balance') }}</label>
                  <span class="font-weight-bold text-h5 text--black">{{ wallet }}</span>
                </div>
              </div>
              <v-text-field v-model="amount" :label="$t('wallet.amount_to_add')"
                            outlined
                            :error="hasError('amount')"
                            :error-messages="getErrors('amount')"
              ></v-text-field>
              <v-radio-group
                  v-model="payment"
                  v-if="amount"
                  row
                  class="black--text"
              >
                <v-radio
                    label="Credit Card"
                    value="credit"
                ></v-radio>
                <v-radio
                    label="SADAD"
                    value="sadad"
                    disabled
                ></v-radio>
                <v-radio
                    label="STC Pay"
                    value="stc"
                    disabled
                ></v-radio>
                <v-radio
                    label="Mada"
                    value="mada"
                ></v-radio>
              </v-radio-group>

              <payment-logos></payment-logos>
            </v-card-text>
          </v-card>
        </div>
        <label>
          <v-checkbox v-model="terms"
                      :error="hasError('terms')"
                      :error-messages="getErrors('terms')"
          ><template v-slot:label>
            <div>
              I agree to platform <a href="#"   @click.stop.prevent="openTerms">Terms & Conditions</a>
            </div>
          </template>

          </v-checkbox>
        </label>
      </v-col>
      <v-col lg="5" cols="12">
        <div class="opportunity-card" v-if="payment === 'credit'">
          <v-card>
            <v-card-text>
              <v-form id="form_1">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="card.number"
                        outlined
                        label="Card Number"
                        placeholder="xxxx xxxx xxxx xxxx"
                        data-paylib="number"
                        inputmode="numeric" pattern="[0-9\s]{13,19}" autocomplete="cc-number" maxlength="19"
                        :error="hasError('card_details.number')"
                        :error-messages="getErrors('card_details.number')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                        outlined
                        v-model="card.cardholder"
                        label="Name of Card Holder"
                        placeholder="Name Surname"
                        :error="hasError('card_details.cardholder')"
                        :error-messages="getErrors('card_details.cardholder')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                        v-model="card.expire_at"
                        outlined
                        label="Exp. At"
                        placeholder="MM/YY"
                        :error="hasError('card_details.expire_at')"
                        :error-messages="getErrors('card_details.expire_at')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                        v-model="card.cvc"
                        outlined
                        label="Security Code"
                        type="password"
                        placeholder="CVC"
                        :error="hasError('card_details.cvc')"
                        :error-messages="getErrors('card_details.cvc')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <div class="mt-5">
      <router-link :to="{name:'Dashboard'}" class="btn btn__cancel mr-4">
        {{ $t('common.back') }}
      </router-link>
      <a href="#" @click.prevent="submit" class="btn mr-4">
        {{ $t('wallet.add_funds')}}
      </a>
      <a :href="link" v-if="link" class="btn btn-success">
        {{ $t('wallet.pay')}}
      </a>
    </div>
    <terms-and-conditions-modal ref="terms"></terms-and-conditions-modal>
  </v-container>
</template>

<script>

import hasApiValidation from "@/mixins/hasApiValidation";
import PaymentLogos from "@/components/PaymentLogos";
import TermsAndConditionsModal from "@/view/pages/TermsAndConditionsModal";

export default {
  name: "AddFunds",
  components: {TermsAndConditionsModal, PaymentLogos},
  mixins: [hasApiValidation],
  data() {
    return {
      wallet: 0,
      payment: 'credit',
      amount: 0,
      link: null,
      terms: false,
      card: {
        number: '',
        expire_at: '',
        cardholder: '',
        cvc: '',
      }
    }
  },
  mounted() {
    this.loadWallet();
  },
  methods: {
    loadWallet() {
      this.$http.get('/wallet').then(({data}) => {
        this.wallet = data.data.balance;
      })
    },
    submit() {
      let data = {
        amount: this.amount,
        terms: this.terms,
      }
      switch (this.payment) {
        case 'credit':
          data.payment = 'credit';
          data.card_details = Object.assign({}, this.card)
          break;
      }
      this.$http.post('/wallet/', data).then(({data}) => {
        this.link = data.redirect_url;
        this.setErrors({})
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      }).finally(() => {
        this.loading = false;
      });
    },
    labelTerms(){
      return ''
    },
    openTerms(){
      this.$refs.terms.open();
    }
  },
  computed: {}
}
</script>

<style scoped lang="scss">
</style>