<template>
  <v-dialog
      v-model="dialog"
      persistent
      :max-width="'600px'"
      @click:outside="outsideClose"
  >
    <v-card>
      <v-col>
        <v-card-title>
          <span class="text-h5 font-weight-bold">{{ title }}</span>
          <v-spacer></v-spacer>
          <font-awesome-icon @click="close()" :icon="['fa', 'times']"/>
        </v-card-title>
      </v-col>
      <v-card-text>
        <p>{{$t('opportunities.recommend_investment')}}</p>
        <v-btn color="warning" @click="submit">{{ $t('common.update') }}</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ConfirmEditModal",
  data: () => ({
    dialog: false,
    model: null,
  }),

  components: {},

  props: {
    title: {
      type: String,
      required: true,
    }
  },
  watch: {},
  computed: {},
  methods: {
    open(model = null) {
      this.dialog = true;
      this.model = model;
      this.$emit('open', {})
    },

    outsideClose() {
      if (!this.preventClose) {
        this.close();
      }
    },

    close(e) {
      this.dialog = false
      this.$emit('close', e)
    },

    submit() {
      this.$emit('submit', this.model)
      this.model = null
      this.dialog = false
    }
  },
}

</script>
