<template>
  <v-app>

    <!-- TODO: Get click login and open form modal -->
    <Header @login="closeAll(); logIn()"
            @signUp="closeAll(); signUp()"
    />

    <v-main>
      <transition
          name="fade"
          mode="out-in"
      >
        <slot/>
      </transition>
    </v-main>

    <app-footer></app-footer>


<!--    <Modal :title="$t('auth.login')" :dialog="dialogSignIn" @close="dialogSignIn = false; email_verified = false">-->
<!--      <SignInForm @sign-up="dialogSignIn = false; signUp()"-->
<!--                  :verified="email_verified"-->
<!--                  @forgot="dialogSignIn = false; $refs.forgot_password_modal.open()"-->
<!--                  ref="sign_in_f"-->
<!--      />-->
<!--    </Modal>-->
    <sign-in-modal :title="$t('auth.login')" :dialog="dialogSignIn" @close="dialogSignIn = false; email_verified = false"
                   :verified="email_verified"
                   @forgot="dialogSignIn = false; $refs.forgot_password_modal.open()"
                   @sign-up="dialogSignIn = false; signUp()"
                   ref="sign_in_f"
    ></sign-in-modal>

    <sign-up-modal :title="$t('auth.sign_up')" ref="sign_up_modal" @login="dialogSignIn = true;"
                   @registered="dialogVerifyYourEmail= true;" @openTerms="openTerms">

    </sign-up-modal>

    <terms-and-conditions-modal ref="terms"></terms-and-conditions-modal>


    <Modal :title="$t('auth.enter_code')"
           description=""
           :dialog="dialogOneTimePassword"
           @close="dialogOneTimePassword = false"
    >
      <OneTimePassword/>
    </Modal>

    <ResetPasswordModal :title="$t('auth.new_password')"
                        ref="reset_password_modal"
                        @sign-up="signUp()"
                        @login="dialogSignIn = true;"
    >
    </ResetPasswordModal>

    <ForgotPasswordModal
        :title="$t('auth.forgot_password')"
        ref="forgot_password_modal"
        @sign-up="signUp()"
    >
    </ForgotPasswordModal>

    <Modal
        :title="$t('auth.verify_your_email')"
        :description="$t('auth.verify_your_email_desc')"
           :dialog="dialogVerifyYourEmail" @close="dialogVerifyYourEmail = false"/>

    <!-- TODO: ConfirmActivateAccount Modal -->
    <!-- TODO: Welcome Modal -->
  </v-app>
</template>

<script>
import Header from '@/components/base/Header'
import Modal from '@/components/Modal'
// import SignInForm from '@/modules/auth/components/Forms/SignInForm'
import OneTimePassword from '@/modules/auth/components/Forms/OneTimePassword'
import ForgotPasswordModal from "@/modules/auth/components/Modals/ForgotPasswordModal";
import ResetPasswordModal from "@/modules/auth/components/Modals/ResetPasswordModal";
import SignUpModal from "@/modules/auth/components/Modals/SignUpModal";
import AppFooter from "@/components/base/AppFooter";
import TermsAndConditionsModal from "@/view/pages/TermsAndConditionsModal";
import SignInModal from "@/modules/auth/components/Modals/SignInModal";

export default {
  data: () => ({
    dialogSignIn: false,
    dialogOneTimePassword: false,
    dialogForgotPassword: false,
    dialogNewPassword: false,
    dialogVerifyYourEmail: false,
    email_verified: false,
  }),

  components: {
    SignInModal,
    TermsAndConditionsModal,
    AppFooter,
    SignUpModal,
    ResetPasswordModal,
    ForgotPasswordModal,
    Header,
    Modal,
    // SignInForm,
    OneTimePassword
  },

  methods: {
    //Todo: get close event any modal and close others
    closeAll() {
      this.dialogSignIn = false;
      this.dialogOneTimePassword = false;
      this.dialogForgotPassword = false;
      this.dialogNewPassword = false;
      this.dialogVerifyYourEmail = false;
    },
    signUp() {
      this.$refs.sign_up_modal.open();
    },
    logIn() {
      this.$refs.sign_in_f.open();
    },
    openTerms(){
      this.$refs.terms.open();
    }
  },

  mounted() {
    if (this.$route.query.email_verified) {
      this.$router.replace({});
      this.email_verified = true;
      this.dialogSignIn = true;
    }
    if (this.$route.query.recovery && this.$route.query.token && this.$route.query.email) {
      this.$refs.reset_password_modal.open({token: this.$route.query.token, email: this.$route.query.email})
      this.$router.replace({});
    }
  },
  watch: {
    dialogSignIn: function (val){
      if (!val) {
          this.$refs.sign_in_f.clear();
      }
    },
  }
}

</script>
<style lang="sass" scoped>
.layout-default
  background: #fff
  text-align: center

  .nav
    padding: 10px 0

    &__link
      padding: 5px

  pre
    text-align: left

</style>
