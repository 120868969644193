export default {
    data: () => ({
        dialog: false
    }),

    props: {

    },

    components: {

    },

    methods: {

    },

    created () {

    }
}
