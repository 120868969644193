<template>
  <v-form>
    <span v-if="mail_verify">{{$t('auth.mail_verified')}}</span>
    <v-row v-if="!codeSended">
      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            outlined
            v-model="form.email"
            :label="$t('auth.email')"
            type="email"
            :error="hasError('email')"
            :error-messages="getErrors('email')"
            required
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="6"
      >
        <v-text-field
            outlined
            v-model="form.password"
            :label="$t('profile.password')"
            type="password"
            :error="hasError('password')"
            :error-messages="getErrors('password')"
            required
        ></v-text-field>
      </v-col>
      <v-col
          cols="12"
          class="py-0">
        <a href="#" @click="sendOTP" class="btn">
          {{ $t('auth.login') }}
        </a>
        <a href="#" @click="resendVerification()" class="ml-1" v-if="hasError('need_verify')">
          {{ $t('auth.resend_verification') }}
        </a>
        <a href="#" class="ml-1" v-if="mail_send">
          {{$t('auth.mail_send')}}
        </a>
      </v-col>
      <v-col>
        <div>
          <small>
            {{$t('auth.dont_have_account_question')}}
            <a href="#" @click.prevent="signUp">{{$t('auth.sign_up')}}</a>
          </small>
        </div>
        <div>
          <small>
            <a href="#" @click.prevent="forgot">{{$t('auth.forgot_password')}}</a>
          </small>
        </div>
      </v-col>
    </v-row>
    <template v-else>
      <v-row>
        <v-col cols="12">
          <label>{{ $t('auth.sent_otp') }}
            {{ $t('auth.enter_code_below') }}</label>
          <v-text-field
              outlined
              v-model="form.code"
              :label="$t('auth.enter_code')"
              placeholder="xxxx"
              :error="hasError('code')"
              :error-messages="getErrors('code')"
              type="text"
              required
          ></v-text-field>
          <a href="#" class="btn mr-2" @click.prevent="onSubmit">
            {{$t('common.submit')}}
          </a>
          <a @click.prevent="sendOTP" href="#">{{$t('common.resend')}}</a>
        </v-col>
      </v-row>
      <v-row class="pb-2">
      </v-row>
      <v-row class="pb-2">
      </v-row>
    </template>
  </v-form>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {

  mixins: [hasApiValidation],
  data: () => ({
    form: {
      email: '',
      password: ''
    },
    codeSended: false,
    mail_verify: false,
    mail_send: false
  }),

  props: ['verified'],

  components: {},
  mounted() {
    this.mail_verify = !!this.verified;
  },
  methods: {
    //Todo: validate
    sendOTP() {
      this.setErrors({});
      this.codeSending = true;
      this.mail_verify = false;
      this.$http.post('/auth/send-otp', this.form).then(() => {
        this.codeSended = true;
        this.setErrors({});
      }).catch(({response}) => {
        this.setErrors(response.data.errors);
      }).finally(() => {
        this.codeSending = false;

      })
    },
    clear() {
      this.form = {
        email: '',
        password: ''
      }
      this.mail_send = false;
      this.setErrors({});
      this.codeSended = false;
    },
    onSubmit() {
      this.mail_verify = false;
      // send login request
      this.$auth
          .login({
            data: this.form,
            redirect: {name: 'opportunity-list'},
            remember: 'funding_user',
            staySignedIn: true,
            fetchUser: true
          })
          .catch(({response}) => {
            this.setErrors(response.data.errors);
          });
    },
    resendVerification() {
      this.$http.post('/auth/resend-verification', {email: this.form.email})
          .then(() => {
            this.mail_send = true;
            this.setErrors({})
          }).catch(() => {

      })
    },
    signUp() {
      this.$emit('sign-up')
    },
    forgot() {
      this.$emit('forgot')
    }
  },

  created() {

  }
}

</script>

<style lang="sass" scoped>

</style>
