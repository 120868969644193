<template>
  <div class="opportunity-details">
    <v-row class="mb-3">
      <v-col cols="12">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.period_date') }}</label>
        <span><b>From</b> {{ opportunity.date_from }} <b>To</b> {{ opportunity.date_to }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.target') }}</label>
        <span>{{ opportunity.target | toCurrency}}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.investment_ceiling') }}</label>
        <span>{{ opportunity.investment_ceiling }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.share_amount') }}</label>
        <span>{{ opportunity.share_amount | toCurrency}}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.evaluation') }}</label>
        <span>{{ opportunity.evaluation| toCurrency(false, 0)}} </span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.target_shares') }}</label>
        <span>{{ opportunity.target_shares | toCurrency(false, 0)}}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.total_shares') }}</label>
        <span>{{ opportunity.total_shares  | toCurrency(false, 0) }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.equitity_offered') }}</label>
        <span>{{ opportunity.equitity_offered}} %</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.roi') }}</label>
        <span>{{ opportunity.roi }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.type') }}</label>
        <span>{{ opportunity.details.type }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.purpose') }}</label>
        <span>{{ opportunity.details.purpose }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.area') }}</label>
        <span>{{ opportunity.details.area }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.designer') }}</label>
        <span>{{ opportunity.details.designer }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.developer') }}</label>
        <span>{{ opportunity.details.developer }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.stakeholder') }}</label>
        <span>{{ opportunity.stakeholder }}</span>
      </v-col>
      <v-col cols="6">
        <label class="c-gray d-block text-uppercase">{{ $t('opportunities.minimum_shares') }}</label>
        <span>{{ opportunity.minimum_shares }}</span>
      </v-col>
    </v-row>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "OpportunityDetails",
  props: ['opportunity'],
}
</script>