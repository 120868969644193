<template>
  <v-dialog
      v-model="dialog"
      persistent
      :max-width="maxWidth + 'px'"
      @click:outside="outsideClose"
  >
    <v-card>
      <v-col>
        <v-card-title>
          <span class="text-h5 font-weight-bold">{{ title }}</span>
          <v-spacer></v-spacer>
          <font-awesome-icon @click="close()" :icon="['fa', 'times']"/>
        </v-card-title>
      </v-col>
      <v-card-text v-if="description">
        {{ description }}
      </v-card-text>
      <v-card-text>
        <v-form @submit.prevent="saveValues">
          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="form.share_amount"
                  :label="$t('opportunities.share_amount')"
                  type="number"
                  outlined
                  required
                  :error="hasError('share_amount')"
                  :error-messages="getErrors('share_amount')"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="form.target"
                  :label="$t('opportunities.target')"
                  type="number"
                  outlined
                  required
                  :error="hasError('target')"
                  :error-messages="getErrors('target')"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="form.evaluation"
                  :label="$t('opportunities.evaluation')"
                  type="number"
                  outlined
                  required
                  :error="hasError('evaluation')"
                  :error-messages="getErrors('evaluation')"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="form.target_shares"
                  :label="$t('opportunities.target_shares')"
                  type="number"
                  outlined
                  required
                  :error="hasError('target_shares')"
                  :error-messages="getErrors('target_shares')"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="form.total_shares"
                  :label="$t('opportunities.total_shares')"
                  readonly
                  type="number"
                  outlined
                  required
                  :error="hasError('total_shares')"
                  :error-messages="getErrors('total_shares')"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="form.equitity_offered"
                  :label="$t('opportunities.equitity_offered') + ' %'"
                  type="number"
                  readonly
                  outlined
                  required
                  :error="hasError('equitity_offered')"
                  :error-messages="getErrors('equitity_offered')"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="form.investment_ceiling"
                  :label="$t('opportunities.investment_ceiling')"
                  type="number"
                  outlined
                  required
                  :error="hasError('investment_ceiling')"
                  :error-messages="getErrors('investment_ceiling')"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="form.roi"
                  :label="$t('opportunities.roi')"
                  type="number"
                  outlined
                  required
                  :error="hasError('roi')"
                  :error-messages="getErrors('roi')"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="form.minimum_shares"
                  :label="$t('opportunities.minimum_shares')"
                  type="number"
                  outlined
                  required
                  :error="hasError('minimum_shares')"
                  :error-messages="getErrors('minimum_shares')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col class="py-0">
              <button type="button" class="btn" @click="askForSave()">
                {{ $t('common.save') }}
              </button>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "ValuesModal",
  data: () => ({
    dialog: false,
    email: '',
    form: {
      share_amount: null,
      target: null,
      investment_ceiling: null,
      roi: null,
      total_shares: null,
      target_shares: null,
      evaluation: null,
      equitity_offered: null,
      minimum_shares: null,
    },
  }),

  mixins: [hasApiValidation],
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 800
    }
  },
  watch: {
    'form.evaluation': function (val) {
      if (val && this.form.share_amount) {
        this.form.total_shares = val / this.form.share_amount;
      }
      if (val && this.form.target) {
        this.form.equitity_offered = Number(this.form.target / val * 100).toFixed(2);
      }
    },
    'form.target': function (val) {
      if (val && this.form.share_amount) {
        this.form.target_shares = val / this.form.share_amount;
      }
      if (val && this.form.evaluation) {
        this.form.equitity_offered = Number(val / this.form.evaluation * 100).toFixed(2);
      }
    },
    'form.share_amount': function (val) {
      if (val && this.form.target) {
        this.form.target_shares = this.form.target / val;
      }
      if (val && this.form.evaluation) {
        this.form.total_shares = this.form.evaluation / val;
      }
    },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.form = {
        share_amount: data.share_amount,
        target: data.target,
        investment_ceiling: data.investment_ceiling,
        roi: data.roi,
        total_shares: data.total_shares,
        target_shares: data.target_shares,
        evaluation: data.evaluation,
        equitity_offered: data.equitity_offered,
        minimum_shares: data.minimum_shares,
        id: data.id,
      }
      this.$emit('open')
    },

    outsideClose() {
      if (!this.preventClose) {
        this.close();
      }
    },

    close(e) {
      this.dialog = false
      this.$emit('close', e)

      this.form = {
        share_amount: null,
        target: null,
        investment_ceiling: null,
        roi: null,
        total_shares: null,
        target_shares: null,
        evaluation: null,
        equitity_offered: null,
      }
      this.setErrors({});
    },
    askForSave() {
      this.$emit('ask_save');
    },
    saveValues() {
      this.setErrors({});
      this.$http.put('/admin/opportunities/' + this.form.id + '/values', this.form).then(({data}) => {
        this.setErrors({});
        this.close();
        this.$emit('saved', data.data)
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      });
    },
  },
}

</script>
