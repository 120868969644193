<template>
  <v-container>
    <investor-form v-if="$auth.user() && $auth.user().role !== 'ADMIN'"></investor-form>
    <admin-form v-if="$auth.user() && $auth.user().role === 'ADMIN'"></admin-form>
  </v-container>
</template>

<script>
import AdminForm from "@/view/pages/Profile/Forms/AdminForm";
import InvestorForm from "@/view/pages/Profile/Forms/InvestorForm";

export default {
  name: "Profile",
  components: {InvestorForm, AdminForm},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    get() {
    },
  },
  computed: {}
}
</script>

<style scoped lang="scss">
.card.opportunity-list-card {
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  color: #0d0b0b;
}

.opportunity-list-media {
  width: 40%;
  position: relative;
  background: var(--darkGreen);
  display: flex;
  align-items: center;
  justify-content: center;
}

.opportunity-list-target {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 15px;
  /* text-align: center; */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
  /* background: red; */
  line-height: 1.1;
}

.opportunity-list-details {
  padding: 5px 10px;
}

.opportunity-list-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

</style>