<template>
  <div>
    <v-toolbar class="rounded-t-lg border-primary" style="border-bottom:2px solid;box-shadow: none" v-if="admin()">
      <v-toolbar-items>
        <v-list-item v-for="tab in tabs" :key="'tab-'+tab.value">
          <v-list-item-title class="pointer font-weight-bold  text--lighten-1"
                             :class="{'primary--text': openTab === tab.value, 'grey--text': openTab !== tab.value}"
                             @click="changeTab(tab)"
          >{{ tab.title }}
          </v-list-item-title>
        </v-list-item>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items class="align-center justify-space-between">
        <router-link :to="{ name: 'opportunity-create' }" class="v-list-item">
          <v-list-item-title class="d-flex align-center text-body-2">
            <v-icon color="primary">mdi-plus</v-icon>
            Add new Opportunity
          </v-list-item-title>
        </router-link>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('common.search')"
            single-line
            @change="getList()"
            hide-details
        ></v-text-field>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
        :options.sync="table.options"
        :headers="headers"
        :items="items"
        :items-per-page="table.pagination.defaultPageSize"
        :loading="table.loading"
        :server-items-length="table.total"
        class="rounded-b-lg"
        :footer-props="{
          itemsPerPageOptions: table.pagination.pageSizeOptions,
        }"
    >
      <template v-slot:item.owners="{ item }">
        <div style="white-space: normal">
          {{ item.owners.map(item => item.name).join(', ') }}
        </div>
      </template>
      <template v-slot:item.status_id="{ item }">
        <div :class="item.status_id === 1 ? 'open' : 'closed'">
          {{ item.status_id === 1 ? 'Open' : 'Closed' }}
        </div>
      </template>
      <template v-slot:item.investor_count="{ item }">
        <router-link :to="{name: 'OpportunityInvestors', params: {id:item.id}}">
          {{ item.investor_count }}
        </router-link>
      </template>

      <template v-slot:item.share_amount="{ item }">
        <span>{{ item.share_amount | toCurrency(false) }}</span>
      </template>
      <template v-slot:item.target="{ item }">
        <span>{{ item.target | toCurrency(false) }}</span>
      </template>
      <template v-slot:item.number_of_shares="{ item }">
        <span>{{ item.number_of_shares | toCurrency(false) }}</span>
      </template>
      <template v-slot:item.raised_amount="{ item }">
        <span>{{ item.raised_amount | toCurrency(false) }}</span>
      </template>
      <template v-slot:item.target="{ item }">
        <span>{{ item.target | toCurrency(false) }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-space-between">
          <a @click="status(item.id, 1)" class="open mr-2 text-decoration-underline"
             v-if="item.status_id !== 1">Activate</a>
          <a @click="askCloseStatus(item)" class="closed  mr-2 text-decoration-underline" v-else>Deactivate</a>
          <a @click="edit(item.id)" class="mr-2">
            <v-icon>mdi-pencil</v-icon>
          </a>
        </div>
        <!--        <a @click="remove(item.id)" class="closed pl-3">-->
        <!--          <font-awesome-icon :icon="['fa', 'times']"/>-->
        <!--        </a>-->
      </template>
    </v-data-table>
    <confirm-close-modal ref="close_modal" :title="$t('opportunities.ask_close')"
                         @confirm="confirmClose"></confirm-close-modal>
    <v-pagination
        v-model="table.options.page"
        :length="table.pages"
        @input="onPageChange"
    >
    </v-pagination>
  </div>
</template>

<script>
import TablePaginationMixin from "@/mixins/tablePagination"
import {mapActions, mapGetters, mapState} from "vuex"
import ConfirmCloseModal from "@/modules/opportunities/ConfirmCloseModal";

export default {
  name: "OpportunityList",
  components: {ConfirmCloseModal},
  data: () => ({
    openTab: 'all',
    search: ''
  }),

  mixins: [TablePaginationMixin],
  watch: {
    'table.options': {
      handler() {
        this.getList()
      },
      deep: true,
    },
  },
  methods: {
    getList() {
      this.table.loading = true;
      this.getOpportunities({...this.table.options, status: this.openTab, search: this.search}).then((data) => {
        if (data.meta) {
          this.table.pages = data.meta.last_page;
          this.table.total = data.meta.total;
        }
      }).finally(() => {
        this.table.loading = false;
      });
    },
    onPageChange() {
      this.getList();
    },
    changeTab(tab) {
      this.openTab = tab.value
      this.getList()
    },
    remove(id) {
      this.$http.delete('/admin/opportunities/' + id).then(() => {
        this.getList();
      })
    },
    askCloseStatus(item) {
      this.$refs.close_modal.open(item);
    },
    confirmClose(item) {
      this.status(item.id, 2)
    },
    status(id, status) {
      this.$http.patch('/admin/opportunities/' + id + '/status', {status: status}).then(() => {
        this.getList();
      })
    },

    edit(id) {
      this.$router.push({
        name: 'opportunity-edit',
        params: {
          id: id
        }
      })
    },
    admin() {
      return this.$auth.user().role === 'ADMIN';
    },
    getData() {
      this.$http.get('/init').then(({data}) => {
        this.$store.dispatch('app/options', data.options)
      });
    },
    ...mapActions({
      getOpportunities: 'getAdminOpportunities'
    }),
  },

  mounted() {
    this.getList();
    this.getData();
  },

  computed: {
    headers() {
      let data = [
        {text: this.$t('opportunities.id'), value: 'id', width: "65px"},
        {text: this.$t('opportunities.title'), value: 'title', width: "65px"},
        {text: this.$t('opportunities.opening_date'), value: 'date_from', width: "120px"},
        {text: this.$t('opportunities.close_date'), value: 'date_to', width: "120px"},
        {text: this.$t('opportunities.owners'), value: 'owners', sortable: false, width: "220px", __class: 'test'},
        {text: this.$t('opportunities.share_amount'), value: 'share_amount', width: "85px"},
        {text: this.$t('opportunities.number_of_shares'), value: 'target_shares', width: "85px"},
        {text: this.$t('opportunities.target'), value: 'target', width: "85px"},
        {text: this.$t('opportunities.raised_amount'), value: 'raised_amount', width: "85px"},
        {text: '%', value: 'roi', width: "85px"},

      ];
      if (this.admin()) {
        data.push({text: this.$t('opportunities.investors_number'), value: 'investor_count', width: "95px"});
        data.push({text: this.$t('opportunities.status'), value: 'status_id', width: "85px"});
        data.push({text: '', value: 'actions', sortable: false, width: "135px"});
      }
      return data
    },
    ...mapGetters({
      items: 'opportunities'
    }),
    ...mapState({
      statuses: state => state.app.options.opportunity_status ?? []
    }),
    tabs() {
      let tabs = [
        {title: 'All', value: 'all'},
      ];
      this.statuses.forEach(item => {
        tabs.push({title: item.name, value: item.id})
      })
      return tabs
    }
  }
}

</script>
<style lang="sass" scoped>
@import "@/assets/scss/var"

.border-primary
  border-color: $cGreen !important

.open
  color: $cGreen !important

.closed
  color: $cRed !important

</style>
