export default {
    // eslint-disable-next-line no-unused-vars
    data() {
        return {
            table: {
                options: {
                    sortBy: ['id'],
                    sortDesc: [true],
                    itemsPerPage: 10,
                    page: 1,
                },
                url: '',
                loading: false,
                pagination: {
                    defaultPageSize: 10,
                    // hideOnSinglePage: true,
                    pageSizeOptions: [10, 25, 50, 100],
                    showSizeChanger: true,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                },
                params: {per_page: 10, page: 1, order_by: 'id', sortDesc: true},
                total: 0,
                pages: 1,
                array: [],
                columns: []
            }
        }
    },
    methods: {
        getTable() {
            this.table.loading = true
            this.$http.get(this.table.url, {params: this.table.params})
                .then(r => {
                    this.table.pagination = Object.assign({}, this.table.pagination, {total: r.meta.total})
                    this.table.array = r.data
                    this.table.loading = false
                    this.$scrollTop(0)
                    if (typeof this.afterGetTable === 'function') this.afterGetTable(r)
                })
                .catch(() => {
                    this.table.loading = false
                })
        },
        tableChange(pagination, filters, sorter) {
            this.table.pagination = Object.assign({}, this.table.pagination, {current: pagination.current})
            this.table.params = {
                per_page: pagination.pageSize,
                page: pagination.current,
                order_by: sorter.columnKey || sorter.field || 'id',
                order_dir: sorter.order ? sorter.order.slice(0, -3) : 'desc'
            }
            this.getTable()
        }
    },
    created() {
        // this.getTable()
    }
}

/*
TODO: change
### In component ###
<a-table :scroll="{ x: 900 }"
         :columns="table.columns"
         :rowKey="record => record.id"
         :dataSource="table.array"
         :pagination="table.pagination"
         :loading="table.loading"
         @change="tableChange" />
### Script ###
add mixin
set table.columns
set table.url
*/
