<template>
  <div class="bg-container contact-wrapper">
    <div class="container relative">
      <div class="row">
        <div class="col-md-6">
          <h1 class="font-weight-bold">{{ $t('contact_us.contact') }}</h1>
          <div class="row">
            <div class="col-md-6">
              <p class="font-weight-bold mb-0 d-flex align-center">
                <v-icon color="black" class="mr-2">mdi-email</v-icon>
                <span>support@digital-arm.net</span></p>
            </div>
            <div class="col-md-6">
              <p class="font-weight-bold mb-0 d-flex align-center">
                <v-icon color="black" class="mr-2">mdi-phone</v-icon>
                <span>+9665330206292 </span></p>
            </div>
            <div class="col-md-6">
              <p class="font-weight-bold mb-0 d-flex align-center">
                <v-icon color="black" class="mr-2">mdi-map-marker</v-icon>
                <span>Riyadh - Beer salman-3575-754</span></p>
            </div>
            <v-form class="col-10" @submit.prevent="submit()">
              <v-text-field
                  outlined
                  v-model="form.name"
                  :label="$t('contact_us.name')"
                  type="text"
                  :error="hasError('name')"
                  :error-messages="getErrors('name')"
                  required
              ></v-text-field>
              <v-text-field
                  outlined
                  v-model="form.subject"
                  :label="$t('contact_us.subject')"
                  type="text"
                  :error="hasError('subject')"
                  :error-messages="getErrors('subject')"
                  required
              ></v-text-field>
              <v-text-field
                  outlined
                  v-model="form.email"
                  :label="$t('contact_us.email')"
                  type="email"
                  :error="hasError('email')"
                  :error-messages="getErrors('email')"
                  required
              ></v-text-field>
              <vue-tel-input-vuetify
                  v-model="form.phone"
                  :label="$t('contact_us.phone')"
                  :error="hasError('phone')"
                  :error-messages="getErrors('phone')"
                  type="phone"
                  defaultCountry="us"
                  mode="international"
                  outlined
              ></vue-tel-input-vuetify>
              <v-textarea
                  v-model="form.message"
                  :label="$t('contact_us.message')"
                  outlined
                  :error="hasError('message')"
                  :error-messages="getErrors('message')"
                  required
              >
              </v-textarea>
              <p class="primary--text" v-if="message">{{message}}</p>
              <button type="submit" class="btn">{{ $t('contact_us.send') }}</button>
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: 'ContactUs',
  mixins: [hasApiValidation],
  data() {
    return {
      form: {
        name: null,
        subject: null,
        email: null,
        phone: null,
        message: null,
      },
      message: '',
    }
  },
  methods: {
    submit() {
      this.setErrors({})
      this.message = '';
      this.$http.post('/contact-us', this.form).then(() => {
        this.message = this.$t('contact_us.success_message');
        this.setErrors({});
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      })
    }
  }
}
</script>
<style lang="scss">
</style>
