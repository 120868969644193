<template>
  <section class="home__contact ">
    <div class="container ">
      <div class="row">
        <div class="col-md-6">
          <h1 class="font-weight-bold mb-4 ">{{$t('home.contact_us')}}</h1>
          <div class="row">
            <div class="col-md-12 d-flex flex-wrap align-baseline ">
              <div class="font-weight-bold mb-0 d-flex align-center mr-4">
                <v-icon color="black" class="mr-1">mdi-phone</v-icon>
                <span>+9665330206292 </span></div>
              <div class="font-weight-bold mb-0 d-flex align-center mr-4">
                <v-icon color="black" class="mr-1">mdi-email</v-icon>
                <span>support@digital-arm.net</span></div>
              <div class="font-weight-bold mb-0 d-flex align-center">
                <v-icon color="black" class="mr-1">mdi-map-marker</v-icon>
                <span>Riyadh - Beer salman-3575-754</span></div>
            </div>
            <social-links wrapper_class="col-md-12 rounded"></social-links>
            <v-form class="col-md-10" @submit.prevent="submit()">
              <v-text-field
                  outlined
                  v-model="form.name"
                  :label="$t('contact_us.name')"
                  type="text"
                  :error="hasError('name')"
                  :error-messages="getErrors('name')"
                  required
              ></v-text-field>
              <v-text-field
                  outlined
                  v-model="form.subject"
                  :label="$t('contact_us.subject')"
                  type="text"
                  :error="hasError('subject')"
                  :error-messages="getErrors('subject')"
                  required
              ></v-text-field>
              <v-text-field
                  outlined
                  v-model="form.email"
                  :label="$t('contact_us.email')"
                  type="email"
                  :error="hasError('email')"
                  :error-messages="getErrors('email')"
                  required
              ></v-text-field>
              <vue-tel-input-vuetify
                  v-model="form.phone"
                  :label="$t('contact_us.phone')"
                  :error="hasError('phone')"
                  :error-messages="getErrors('phone')"
                  type="phone"
                  defaultCountry="us"
                  mode="international"
                  outlined
              ></vue-tel-input-vuetify>
              <v-textarea
                  v-model="form.message"
                  :label="$t('contact_us.message')"
                  outlined
                  :error="hasError('message')"
                  :error-messages="getErrors('message')"
                  required
              >
              </v-textarea>
              <p class="primary--text" v-if="message">{{ message }}</p>
              <button type="submit" class="btn">{{ $t('contact_us.send') }}</button>
            </v-form>
          </div>
        </div>
        <div class="col-md-6">
          <GmapMap
              :options="mapOptions"
              :center="{
                lat:24.7491615,
                lng: 46.6161086
              }"
              :zoom="15"
              style="width: 100%; height:600px"
              ref="g_map"
          >
            <GmapMarker
                        :position="{
                          lat:24.7491615,
                          lng: 46.6161086
                        }"
                        :clickable="false"
                        :draggable="false"
                        icon="/assets/images/marker.svg"
            />
          </GmapMap>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import SocialLinks from "@/view/pages/Home/SocialLinks";

export default {
  name: "ContactSection",
  components: {SocialLinks},
  mixins: [hasApiValidation],
  data() {
    return {
      form: {
        name: null,
        subject: null,
        email: null,
        phone: null,
        message: null,
      },
      message: '',
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      },
    }
  },
  methods: {
    submit() {
      this.setErrors({})
      this.message = '';
      this.$http.post('/contact-us', this.form).then(() => {
        this.message = this.$t('contact_us.success_message');
        this.setErrors({});
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>