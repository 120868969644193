<template>
  <OpportunityForm :form-data="opportunity"/>
</template>

<script>
import OpportunityForm from "./OpportunityForm"

export default {
  data: () => ({
    opportunity: null
  }),

  components: {
    OpportunityForm
  },

  methods: {
    getOpportunity(id) {
      this.$http.get('/app/opportunities/' + id).then(({data}) => {
        this.opportunity = data.data
      });
    }
  },

  mounted() {
    this.getOpportunity(this.$route.params.id)
  },

  created() {

  }
}

</script>

<style lang="scss"></style>
