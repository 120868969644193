import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

import {
    faCircle,
    faUser, faLock, faSignOutAlt, faCog, faPlusCircle, faPlus, faMinus, faMinusCircle, faArrowCircleLeft,
    faList, faCaretRight, faCaretLeft, faCaretDown, faFileAlt, faBuilding, faCogs, faTachometerAlt, faChartLine, faHospitalUser, faAlignLeft, faAlignRight,
    faEdit, faTrashAlt, faTrashRestoreAlt, faDumpster, faEllipsisV, faEye, faExternalLinkAlt,
    faCheck, faTimes, faStop, faUserCheck,
    faUndo, faRedo, faBold, faItalic, faUnderline, faStrikethrough, faParagraph, faHeading, faListUl, faListOl,
    faHome, faGlobe, faImage
} from '@fortawesome/free-solid-svg-icons'

import {
    faBuilding as farBuilding,
    faUser as farUser
} from '@fortawesome/free-regular-svg-icons'

import {
    faGithub
} from '@fortawesome/free-brands-svg-icons'

library.add(
    faCircle,
    faUser,
    faLock,
    faSignOutAlt,
    faCog,
    faGithub,
    faPlusCircle,
    faPlus,
    faMinusCircle,
    faMinus,
    faArrowCircleLeft,
    faAlignLeft,
    faAlignRight,
)

// Menu
library.add(
    faList,
    faCaretRight,
    faCaretLeft,
    faCaretDown,
    faFileAlt,
    faBuilding,
    farUser,
    farBuilding,
    faCogs,
    faTachometerAlt,
    faChartLine,
    faHospitalUser
)

// Actions
library.add(
    faEdit,
    faTrashAlt,
    faTrashRestoreAlt,
    faDumpster,
    faEllipsisV,
    faEye,
    faExternalLinkAlt
)

// Moderation
library.add(
    faCheck,
    faTimes,
    faStop,
    faUserCheck
)

// Text-editor
library.add(
    faUndo,
    faRedo,
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faParagraph,
    faHeading,
    faListUl,
    faListOl
)

// Forms
library.add(
    faHome,
    faGlobe,
    faImage
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
