<template>
  <v-container fluid>
    <v-row class="justify-space-between">
      <v-col cols="12" md="8">
        <h2 class="font-weight-bold">{{ $t('pages.opportunities') }}</h2>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
            v-model="search"
            :label="$t('common.search')"
            class="details_none"
            outlined
            @change="searchData()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" v-for="item in items" :key="'opp-'+item.id">
        <opportunity-list-item :item="item" route_name="ViewOpportunity"></opportunity-list-item>
      </v-col>
    </v-row>
    <v-pagination
        v-model="meta.page"
        :length="meta.pages"
        @input="onPageChange"
    >
    </v-pagination>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import OpportunityListItem from "@/view/pages/Opportunities/OpportunityListItem";

export default {
  name: "Opportunities",
  components: {OpportunityListItem},
  data() {
    return {
      search: "",
      meta: {
        page: 1,
        pages: 1,
        total: 0,
        loading: false,
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.meta.loading = true;
      this.getOpportunities({search: this.search, page: this.meta.page}).then((data) => {
        if (data.meta) {
          this.meta.pages = data.meta.last_page;
          this.meta.total = data.meta.total;
        }
      }).finally(() => {
        this.meta.loading = false;
      });
    },
    onPageChange() {
      this.getData();
    },
    searchData() {
      this.meta.page = 1;
      this.getData();
    },
    ...mapActions({
      getOpportunities: 'getOpportunities'
    }),
  },
  computed: {
    ...mapGetters({
      items: 'opportunities'
    }),
  }
}
</script>

<style scoped lang="scss">

.card.opportunity-list-card {
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  color: #0d0b0b;
  flex-direction: column;

  @media screen and (min-width: 768px){
    flex-direction: row;
  }
}

.opportunity-list-media {
  position: relative;
  background: var(--darkGreen);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  @media screen and (min-width: 768px) {

    width: 40%;
    flex-direction: row;
    height: unset;
  }
}


.opportunity-list-target {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 15px;
  /* text-align: center; */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
  /* background: red; */
  line-height: 1.1;
}

.opportunity-list-details {
  padding: 5px 10px;
}

.opportunity-list-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

</style>