<template>
  <div>
    <span class="d-inline-flex align-center"><v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
      {{ $t('profile.info') }}</span>
    <h2 class="font-weight-bold">{{ $t('profile.title') }}</h2>
    <v-card class="mb-5">
      <v-card-text>
        <v-row>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.first_name"
                :label="$t('profile.first_name')"
                type="text"
                :error="hasError('user.first_name')"
                :error-messages="getErrors('user.first_name')"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.last_name"
                :error="hasError('user.last_name')"
                :error-messages="getErrors('user.last_name')"
                :label="$t('profile.last_name')"
                type="text"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.national_id"
                :label="$t('profile.national_id')"
                :error="hasError('user.national_id')"
                :error-messages="getErrors('user.national_id')"
                disabled
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-row dense>
              <v-col cols="10">
                <vue-tel-input-vuetify
                    v-model="form.user.phone"
                    :error="hasError('user.phone')"
                    :error-messages="getErrors('user.phone')"
                    :label="$t('profile.phone')"
                    type="phone"
                    defaultCountry="us"
                    mode="international"
                    outlined
                    disabled
                    append-icon="mdi-close"
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col cols="2">
                <v-btn color="primary" small class="custom_append" @click="openPhoneModal()">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.email"
                :label="$t('profile.email')"
                type="email"
                :error="hasError('user.email')"
                :error-messages="getErrors('user.email')"
                class="details_none"
                required
            ></v-text-field>
            <a href="#" v-if="getEmailVerified()" @click.prevent="resendVerification()">{{ getEmailVerified() }}</a>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-select
                outlined
                v-model="form.details.nationality"
                :label="$t('profile.nationality')"
                :error="hasError('details.nationality')"
                :error-messages="getErrors('details.nationality')"
                item-text="name"
                item-value="id"
                :items="[{id: 1, name: 'Nationality 1'}, {id: 2, name: 'Nationality 2'}]"
                required
            ></v-select>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.details.income"
                :label="$t('profile.income')"
                :error="hasError('details.income')"
                :error-messages="getErrors('details.income')"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.user.birthdate"
                :label="$t('profile.birthdate')"
                type="date"
                disabled
                :error="hasError('user.birthdate')"
                :error-messages="getErrors('user.birthdate')"
                required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-text>
        <h2 class="font-weight-bold mb-4">{{ $t('profile.address_info') }}</h2>
        <v-row>
          <v-col
              cols="12"
              sm="5"
          >
            <v-select
                outlined
                item-text="name"
                item-value="id"
                :items="[{id: 1, name: 'City 1'}, {id: 2, name: 'City 2'}]"
                v-model="form.address.city"
                :label="$t('profile.city')"
                type="text"
                :error="hasError('address.city')"
                :error-messages="getErrors('address.city')"
                required
            ></v-select>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-select
                outlined
                item-text="name"
                item-value="id"
                :items="[{id: 1, name: 'District 1'}, {id: 2, name: 'District 2'}]"
                v-model="form.address.district"
                :label="$t('profile.district')"
                type="text"
                :error="hasError('address.district')"
                :error-messages="getErrors('address.district')"
                required
            ></v-select>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.address.street"
                :label="$t('profile.street')"
                type="text"
                :error="hasError('address.street')"
                :error-messages="getErrors('address.street')"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.address.po_box"
                :label="$t('profile.po_box')"
                type="text"
                :error="hasError('address.po_box')"
                :error-messages="getErrors('address.po_box')"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.address.postal_code"
                :label="$t('profile.postal_code')"
                type="text"
                :error="hasError('address.postal_code')"
                :error-messages="getErrors('address.postal_code')"
                required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-text>
        <h2 class="font-weight-bold mb-4">{{ $t('profile.bank_info') }}</h2>
        <v-row>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.bank_account.holder_name"
                :label="$t('profile.holder_name')"
                type="text"
                :error="hasError('bank_account.holder_name')"
                :error-messages="getErrors('bank_account.holder_name')"
                required
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.bank_account.iban"
                :label="$t('profile.iban')"
                type="text"
                :error="hasError('bank_account.iban')"
                :error-messages="getErrors('bank_account.iban')"
                required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-5">
      <v-card-text>
        <h2 class="font-weight-bold mb-4">{{ $t('profile.user_acc_info') }}</h2>
        <v-row>
          <v-col cols="12" sm="5">
            <div class="file_uploads d-flex flex-nowrap align-start">
              <v-file-input
                  v-model="file_upload"
                  :label="$t('profile.profile_photo')"
                  outlined
                  accept="image/*"
                  :error="hasError('file')"
                  :error-messages="getErrors('file')"
              />
              <button type="button" class="btn ml-2 py-4" v-if="file_upload" @click="upload_media">
                <v-icon dark>mdi-cloud-upload</v-icon>
              </button>
              <br>
            </div>
            <v-row class="mb-3">
              <v-col cols="3" v-if="user_photo">
                <div class="media-preview" :class="{'media_deleting': media_deleting = user_photo.id}">
                  <a class="media_delete" @click.prevent="deleteMedia()">
                    <v-icon>mdi-close-circle</v-icon>
                  </a>
                  <a :href="user_photo.url" target="_blank" :title="user_photo.title" class="link">
                    <v-img
                        contain
                        :src="user_photo.url"
                    ></v-img>
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="12"
              sm="5"
          >
            <v-text-field
                outlined
                v-model="form.account.password"
                :label="$t('profile.password')"
                type="password"
                autocomplete="new-password"
                :error="hasError('account.password')"
                :error-messages="getErrors('account.password')"
            ></v-text-field>
            <v-text-field
                outlined
                v-model="form.account.password_confirmation"
                :label="$t('auth.confirm_password')"
                type="password"
                autocomplete="new-password"
                :error="hasError('account.password_confirmation')"
                :error-messages="getErrors('account.password_confirmation')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="d-flex  align-center py-0">
      <a href="#" @click="submit" class="btn">
        {{ $t('common.save') }}
      </a>
      <router-link :to="{name:'Dashboard'}" href="#" class="btn btn__cancel ml-4">
        {{ $t('common.cancel') }}
      </router-link>
      <a href="#" @click.prevent="downloadAgreement" class="btn btn__info ml-4">{{$t('profile.download_investment_agreement')}}</a>
      <v-snackbar
          v-model="saved"
          :timeout="2000"
      >
        <template>
          {{ $t('common.saved') }}

        </template>
      </v-snackbar>
    </div>
    <phone-number-modal title="Change Phone Number" ref="phone_modal" @updated="getData()"></phone-number-modal>
  </div>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import PhoneNumberModal from "@/view/pages/Profile/Forms/PhoneNumberModal";

export default {
  name: "InvestorForm",
  components: {PhoneNumberModal},
  mixins: [hasApiValidation],
  data() {
    return {
      user_photo: null,
      media_deleting: null,
      file_upload: null,
      saved: false,
      email_not_verified: false,
      form: {
        user: {
          national_id: '',
          birthdate: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          password_confirmation: '',
        },
        details: {
          income: 0,
          nationality: null,
        },
        address: {
          city: null,
          district: null,
          street: '',
          po_box: '',
          postal_code: ''
        },
        bank_account: {
          holder_name: '',
          iban: '',
        },
        account: {
          password: '',
          photo: null,
          password_confirmation: '',
        }
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    openPhoneModal() {
      this.$refs.phone_modal ? this.$refs.phone_modal.open() : null;
    },
    getData() {
      this.$http.get('/profile').then(({data}) => {
        Object.assign(this.form.user, data.data.user);
        Object.assign(this.form.details, data.data.details);
        Object.assign(this.form.address, data.data.address);
        Object.assign(this.form.bank_account, data.data.bank_account);
        this.user_photo = data.data.photo;
        this.email_not_verified = !data.data.user.email_verified_at;
      })
    },
    submit() {
      this.setErrors({});
      this.form.account.photo = this.user_photo ? this.user_photo.id : null;
      this.$http.post('/profile/', this.form).then(({data}) => {
        this.saved = true;
        this.email_not_verified = !data.data.user.email_verified_at;
        this.setErrors({});
      }).catch(({response}) => {
        this.setErrors(response.data.errors);
      });
    },

    downloadAgreement(){
      this.$http.get('/profile/investment_agreement', {responseType: 'blob'}).then((res) => {
        this.downloadFile(res, 'investment_agreement.pdf');
      })
    },
    downloadFile(response, fname) {
      let blob = new Blob([response.data], {type: response.headers["content-type"]});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fname;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    deleteMedia() {
      this.user_photo = null;
    },
    getEmailVerified() {
      if (!this.email_not_verified) {
        return false;
      }
      return this.$t('profile.email_need_verify')
    },
    upload_media() {
      let data = new FormData();
      data.append('file', this.file_upload);
      this.$http.post('/profile_image', data).then(({data}) => {
        this.user_photo = data.data
        this.file_upload = null;
        if (this.errors.file) {
          delete this.errors.file
        }
      }).catch((err) => {
        this.errors = {...this.errors, file: err.response.data.errors.file}
      });
    },
    resendVerification() {
      this.$http.post('/auth/resend-verification', {email: this.form.user.email})
          .then(() => {
            this.$noty.success(this.$t('common.send'))
          }).catch(() => {
      })
    },
  }
}
</script>

<style scoped lang="scss">

</style>