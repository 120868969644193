<template>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="maxWidth + 'px'"
        @click:outside="outsideClose"
    >
      <v-card>
        <v-col>
          <v-card-title>
          <span class="text-h5 font-weight-bold">{{title}}</span>
          <v-spacer></v-spacer>
          <font-awesome-icon @click="close()" :icon="['fa', 'times']" />
        </v-card-title>
        </v-col>
        <v-card-text v-if="description">
            {{description}}
        </v-card-text>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" v-if="send">
                {{send}}
              </v-col>
              <v-col cols="12">
                <v-text-field
                    outlined
                    v-model="email"
                    :label="$t('auth.email')"
                    type="email"
                    :error="hasError('email')"
                    :error-messages="getErrors('email')"
                    required
                ></v-text-field>
              </v-col>
              <v-col class="py-0 pb-3">
                <a href="#" class="btn" @click="resetPass">
                  {{ $t('auth.reset_password')}}
                </a>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <SignUpLink @sign-up="$emit('sign-up');close()"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script >

import hasApiValidation from "@/mixins/hasApiValidation";
import SignUpLink from "@/modules/auth/components/Helpers/SignUpLink/index";

export default {
  name: "ForgotPasswordModal",
  data: () => ({
    dialog: false,
    email: '',
    send: '',
  }),

  components: {
    SignUpLink
  },

  mixins: [hasApiValidation],
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 800
    }
  },

  methods: {
    open (e) {
      this.dialog = true;
      this.$emit('open', e)
    },

    outsideClose() {
      if (!this.preventClose) {
        this.close();
      }
    },

    close (e) {
      this.dialog = false
      this.$emit('close', e)
    },

    resetPass() {
      this.$http.post('/auth/forgot', {email: this.email}).then(({data}) => {
        this.send = data.message;
        this.setErrors({})
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      })
    }
  },
}

</script>
