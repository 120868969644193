import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueBus from 'vue-bus'
import vClickOutside from 'v-click-outside'
import VueDebounce from 'vue-debounce'

import '@/assets/scss/app.sass'
import '@/core/config/filters'
import '@/core/config/prototypes'
import vuetify from '@/core/plugins/vuetify/vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import '@/core/plugins/fontawesome'

import i18n from '@/core/plugins/i18n'
import store from '@/store'
import router from '@/router'
import axios from '@/request/index'


// import ErrorMessage from './components/ErrorMessage'
// import FormError from './components/FormError'
// import InputCode from './components/InputCode'
// import Modal from './components/Modal'
// import InlineSvg from './components/InlineSvg'
import auth from './core/auth';
import './modules/chart';
import App from './App.vue'

//import { apiInit } from '@/modules/auth/api/auth'

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueTelInputVuetify, {
    vuetify,
});

import VueNoty from 'vuejs-noty'
import 'vuejs-noty/dist/vuejs-noty.css'

Vue.use(VueNoty)

Vue.filter('toCurrency', function (value, currency = true, minimumFractionDigits = 2) {
    let options = {
        minimumFractionDigits: minimumFractionDigits
    };
    if (currency) {
        options.currency = 'SAR'
        options.style = 'currency';
    }
    let num = Number.parseFloat(value);
    if (typeof num !== "number") {
        return value;
    }
    let formatter = new Intl.NumberFormat('en-US',options);
    return formatter.format(num);
});

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY,
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    installComponents: true,
})

Vue.config.productionTip = false
Vue.use(VueBus)
Vue.use(vClickOutside)
Vue.use(VueDebounce, {
    cancelOnEmpty: true,
    defaultTime: '1s',
    listenTo: ['input', 'keyup']
})
// Vue.component('ErrorMessage', ErrorMessage)
// Vue.component('FormError', FormError)
// Vue.component('InputCode', InputCode)
// Vue.component('Modal', Modal)
// Vue.component('InlineSvg', InlineSvg)


// const files = require.context('./assets/svg-inline/', false, /\.svg$/)
// files.keys().forEach(files)


// eslint-disable-next-line no-unused-vars
//const app = () => {
new Vue({
    el: '#app',
    vuetify,
    i18n,
    store,
    router,
    http: axios,
    auth: auth,
    ...App
})
//}

// apiInit()
//     .then(r => {
//       store.dispatch('app/options', r.options)
//       store.dispatch('auth/user', r.user).then(app)
//     })
//     .catch(() => {
//       store.dispatch('auth/user').then(app)
//     })
