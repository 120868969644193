import Vue from 'vue'
import moment from 'moment'
import { serialize } from 'object-to-formdata'
// import router from '@/core/plugins/router'
import * as $const from '@/core/config/const'
//import { apiLogout } from '@/core/modules/auth/api/'
//import { removeToken, setToken } from '@/utils/localStorage'
//import { validator, fields } from '@/utils/validator'


Vue.prototype.$const = $const
Vue.prototype.$moment = moment
Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL
Vue.prototype.$isObject = v => !!v && typeof v === 'object' && !Array.isArray(v)
Vue.prototype.$deepClone = v => JSON.parse(JSON.stringify(v))
Vue.prototype.$objectToFormData = v => serialize(v, { indices: true })
Vue.prototype.$scrollTop = v => window.scrollTo({ top: v || 0, behavior: 'smooth' })

/**
 * Get an item from an array or object using "dot" notation.
 *
 * @param {object} object
 * @param {string} path
 * @param {*} value
 * @return {*}
 */
Vue.prototype.$get = (object, path, value = undefined) => {
    return path.split('.').reduce((prev, key) => prev && prev[key], object) || value
}



/**
 * Simple notification
 *
 * @param {string|null} message
 * @param {int} type - 0 error | 1 success
 */
Vue.prototype.$msg = (message = null, type = 0) => {
    if (!message || typeof message !== 'string') message = 'Something went wrong.'
    Vue.prototype.$message[type !== 1 ? 'error' : 'success'](message, 5)
}

/** Auth system */
// Vue.prototype.$auth = {
//     login (user, token) {
//         store.dispatch('auth/user', user)
//         axios.defaults.headers.Authorization = `Bearer ${token}`
//         setToken(token)
//     },
//     logout () {
//         apiLogout()
//         removeToken()
//         delete axios.defaults.headers.Authorization
//         store.dispatch('auth/user', false)
//         if (router.currentRoute.name !== MAIN_ROUTE) router.push({ name: MAIN_ROUTE })
//     }
// }

/**
 * Validate input value
 *
 * @param {any} val - input value
 * @param {object} errors - link to errors object
 * @param {string} key - key of input
 * @param {?string} param - key of validation
 */
// Vue.prototype.$blurInput = (val, errors, key, param = null) => {
//     Vue.prototype.$set(errors, key, null)
//     validator({ [key]: val }, {
//         [key]: fields[param || key]
//     })
//         .catch(e => {
//             if (e.errors[key]) Vue.prototype.$set(errors, key, e.errors[key])
//         })
// }

/**
 * Splice item in array
 *
 * @param {array} array - array of items
 * @param {object|string|number} item - can be object or number/string
 * @param {string} key - key of item (if item is object)
 */
Vue.prototype.$splice = (array, item, key = 'id') => {
    const i = typeof item !== 'object'
        ? array.indexOf(item)
        : array.map(el => el[key]).indexOf(item[key])
    if (i > -1) {
        array.splice(i, 1)
    } else {
        array.push(item)
    }
}
