<template>
  <div>
    <h2 class="font-weight-bold">{{ $t('pages.transactions') }}</h2>
    <v-card>

      <v-toolbar class="rounded-t-lg border-primary" style="border-bottom:2px solid;box-shadow: none">
        <v-toolbar-items>
          <v-list-item v-for="tab in tabs" :key="'tab-'+tab.value">
            <v-list-item-title class="pointer font-weight-bold  text--lighten-1"
                               :class="{'primary--text': openTab === tab.value, 'grey--text': openTab !== tab.value}"
                               @click="changeTab(tab)"
            >{{ tab.title }}
            </v-list-item-title>
          </v-list-item>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-toolbar-items>

          <v-menu
              ref="dates_menu"
              v-model="dates_menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center justify-center">
              <span v-if="dates.length > 0" class="mr-3 text--secondary">Selected Dates: {{dates[0]}} {{ dates[1]?'-':'' }} {{dates[1]}}</span>
              <a class="c-green"
                    v-bind="attrs"
                    v-on="on"
              ><v-icon class="c-green">mdi-calendar</v-icon> Select Dates </a>
              </div>
            </template>
            <v-date-picker
                v-model="dates" no-title scrollable
                range
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dates_menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dates_menu.save(dates);getList()">OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
          :options.sync="table.options"
          :headers="headers"
          :items="transactions"
          :items-per-page="table.pagination.defaultPageSize"
          :loading="table.loading"
          :server-items-length="table.total"
          class="rounded-b-lg"
          :footer-props="{
          itemsPerPageOptions: table.pagination.pageSizeOptions,
        }"
      >
      </v-data-table>

      <v-pagination
          v-model="table.options.page"
          :length="table.pages"
          @input="onPageChange"
      >
      </v-pagination>
    </v-card>
  </div>
</template>

<script>
import TablePaginationMixin from "@/mixins/tablePagination"

export default {
  name: "Transactions",
  data: () => ({
    openTab: 'all',
    transactions: [],
    table: {
      options: {
        sortBy: ['created_at'],
      },
    },
    dates: [],
    dates_menu: false,
  }),

  mixins: [TablePaginationMixin],
  watch: {
    'table.options': {
      handler() {
        this.getList()
      },
      deep: true,
    },
  },
  methods: {
    getList() {
      this.table.loading = true;
      this.$http.get('/transactions', {params: {...this.table.options, type: this.openTab, dates: this.dates}}).then(({data}) => {
        this.transactions = data.data
        if (data.meta) {
          this.table.pages = data.meta.last_page;
          this.table.total = data.meta.total;
        }
      }).finally(() => {
        this.table.loading = false;
      });
    },
    onPageChange() {
      this.getList();
    },
    changeTab(tab) {
      this.openTab = tab.value
      this.getList()
    },
  },

  mounted() {
    // this.getList();
  },

  computed: {
    headers() {
      return [
        {text: 'Date', value: 'created_at', width: "100px"},
        {text: 'Description', value: 'description', width: "100px"},
        {text: 'Type', value: 'type', width: "100px"},
        {text: 'Balance Before', value: 'balance_before', width: "85px"},
        {text: 'Amount', value: 'amount', width: "100px"},
        {text: 'Balance After', value: 'balance_after', width: "85px"},
      ]
    },
    tabs() {
      return [
        {title: 'All', value: 'all'},
      ]
    }
  }
}

</script>
<style lang="sass" scoped>
@import "@/assets/scss/var"

.border-primary
  border-color: $cGreen !important

.open
  color: $cGreen !important

.closed
  color: $cRed !important

</style>
