<template>
  <v-dialog
      v-model="dialog"
      persistent
      :max-width="maxWidth + 'px'"
      @click:outside="outsideClose"
      :content-class="'not_scroll'"
      id="test"
  >
    <v-card>
      <v-col>
        <v-card-title>
          <span class="text-h5 font-weight-bold" v-if="!code_requested">{{ title }}</span>
          <span class="text-h5 font-weight-bold" v-else>{{ $t('auth.enter_code') }}</span>
          <v-spacer></v-spacer>
          <font-awesome-icon @click="close()" :icon="['fa', 'times']"/>
        </v-card-title>
      </v-col>
      <v-card-text v-if="description">
        {{ description }}
      </v-card-text>
      <v-card-text>
        <v-form @submit.prevent="signUp">
          <template v-if="!code_requested">
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.national_id"
                    :label="$t('profile.national_id')"
                    :error="hasError('national_id')"
                    :error-messages="getErrors('national_id')"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.birthdate"
                    :label="$t('profile.birthdate')"
                    type="date"
                    :error="hasError('birthdate')"
                    :error-messages="getErrors('birthdate')"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.first_name"
                    :label="$t('profile.first_name')"
                    type="text"
                    :error="hasError('first_name')"
                    :error-messages="getErrors('first_name')"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.last_name"
                    :error="hasError('last_name')"
                    :error-messages="getErrors('last_name')"
                    :label="$t('profile.last_name')"
                    type="text"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.email"
                    :label="$t('profile.email')"
                    type="email"
                    :error="hasError('email')"
                    :error-messages="getErrors('email')"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <vue-tel-input-vuetify
                    v-model="form.phone"
                    :label="$t('profile.phone')"
                    :error="hasError('phone')"
                    :error-messages="getErrors('phone')"
                    type="phone"
                    defaultCountry="us"
                    mode="international"
                    outlined
                ></vue-tel-input-vuetify>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.password"
                    :error="hasError('password')"
                    :error-messages="getErrors('password')"
                    :label="$t('profile.password')"
                    type="password"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.password_confirmation"
                    :label="$t('auth.confirm_password')"
                    :error="hasError('password_confirmation')"
                    :error-messages="getErrors('password_confirmation')"
                    type="password"
                    required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
              >
                <v-text-field
                    outlined
                    v-model="form.bank.holder_name"
                    :label="$t('profile.bank_holder_name')"
                    type="text"
                    :error="hasError('bank.holder_name')"
                    :error-messages="getErrors('bank.holder_name')"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.bank.iban"
                    :label="$t('profile.iban')"
                    type="text"
                    :error="hasError('bank.iban')"
                    :error-messages="getErrors('bank.iban')"
                    required
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    outlined
                    v-model="form.bank.iban_confirmation"
                    :label="$t('profile.iban_confirmation')"
                    type="text"
                    :error="hasError('bank.iban_confirmation')"
                    :error-messages="getErrors('bank.iban_confirmation')"
                    required
                ></v-text-field>
              </v-col>
            </v-row>

            <label class="d-block mb-3">
              <v-checkbox v-model="form.terms"
                          :error="hasError('terms')"
                          :error-messages="getErrors('terms')"
              >
                <template v-slot:label>
                  <div>
                    I agree to platform <a href="#" @click.stop.prevent="openTerms">Terms & Conditions</a>
                  </div>
                </template>
              </v-checkbox>
            </label>

            <label class="d-block mb-3">
              <v-checkbox v-model="form.investment_agreement"
                          :error="hasError('investment_agreement')"
                          :error-messages="getErrors('investment_agreement')"
              >
                <template v-slot:label>
                  <div>
                    I agree to platform <a href="/" target="_blank" @click.exact.stop.prevent="downloadAgreement()">Investment
                    Agreement</a>
                  </div>
                </template>
              </v-checkbox>
            </label>

          </template>
          <template v-else>
              <span class="font-weight-bold mb-2 d-block">
                {{ $t('auth.sent_otp') }}<br>{{ $t('auth.enter_code_below') }}</span>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    outlined
                    v-model="form.code"
                    :label="$t('auth.enter_code')"
                    placeholder="xxxx"
                    :error="hasError('code')"
                    :error-messages="getErrors('code')"
                    type="text"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col class="py-0">
              <button type="submit" class="btn">
                {{ $t('auth.sign_up') }}
              </button>
              <a href="#" @click.prevent="send" v-if="code_requested" class="d-block mt-2"> {{
                  $t('common.resend')
                }} </a>
              <SignInLink @login="login" v-else class="d-block mt-2"/>
            </v-col>
            <v-col>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import hasApiValidation from "@/mixins/hasApiValidation";
import SignInLink from "@/modules/auth/components/Helpers/SignInLink/index";

export default {
  name: "SignUpModal",
  data: () => ({
    dialog: false,
    email: '',
    form: {
      national_id: '',
      birthdate: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      code: '',
      terms: false,
      investment_agreement: false,
      bank: {
        iban: '',
        holder_name: '',
        iban_confirmation: '',
      }
    },
    code_requested: false,
  }),

  components: {
    SignInLink
  },

  mixins: [hasApiValidation],
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 800
    }
  },

  methods: {
    openTerms() {
      this.$emit('openTerms')
    },
    open(e) {
      this.dialog = true;
      this.$emit('open', e)
    },

    outsideClose() {
      if (!this.preventClose) {
        this.close();
      }
    },

    close(e) {
      this.dialog = false
      this.$emit('close', e)

      this.form = {
        national_id: '',
        birthdate: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        code: '',
        bank: {
          iban: '',
          holder_name: '',
          iban_confirmation: '',
        }
      }
      this.code_requested = false;
      this.setErrors({});
    },

    send() {
      this.$http.post('/auth/register-otp', this.form).then(() => {
        this.code_requested = true;
        this.setErrors({});
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      });
    },
    downloadAgreement() {
      this.setErrors({});
      this.$http.post('/auth/investment_agreement', {...this.form, store: true},).then(() => {
        this.setErrors({});
        this.$http.post('/auth/investment_agreement', this.form, {responseType: 'blob'}).then((res) => {
          this.downloadFile(res, 'investment_agreement.pdf');
        })
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      })
    },
    downloadFile(response, fname) {
      let blob = new Blob([response.data], {type: response.headers["content-type"]});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fname;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    signUp() {
      this.setErrors({})
      if (!this.code_requested) {
        this.send();
      } else {
        this.$http.post('/auth/register', this.form).then(() => {
          this.code_requested = true;
          this.close();
          this.$emit('registered')
          this.setErrors({});
        }).catch(({response}) => {
          this.setErrors(response.data.errors)
        })
      }
    },
    login() {
      this.close();
      this.$emit('login')
    }
  },
}

</script>
<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen).not_scroll{
  max-height: 98%;
  .col-sm-6, .col-12{
    padding: 3px 12px;
  }
}
</style>