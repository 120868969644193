<template>
  <section class="home__faq relative">
    <div class="container py-5">
      <h1 class="font-weight-bold mb-4 ">{{ $t('home.faq') }}</h1>
      <div class="items">
        <div class="card" v-for="(item, index) in $t('home.faq_items')" :key="'faq-'+index">
          <div class="card-header" @click="toggle(index)">
            <h3 class="font-weight-bold">{{ item.title }}</h3>
            <div class="toggle">
              <i class="fa fa-minus" v-if="open === index"></i>
              <i class="fa fa-plus" v-else></i>
            </div>
          </div>
          <div class="card-body" v-show="open === index">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FaqSection",
  data() {
    return {
      open: null,
    }
  },
  methods: {
    toggle(id) {
      if (this.open === id) {
        this.open = null;
      } else {
        this.open = id
      }

    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 30px;

  .card-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .card-body {
    padding: 20px;
  }
}

.toggle {
  font-size: 26px;
}

</style>