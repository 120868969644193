var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.admin())?_c('v-toolbar',{staticClass:"rounded-t-lg border-primary",staticStyle:{"border-bottom":"2px solid","box-shadow":"none"}},[_c('v-toolbar-items',_vm._l((_vm.tabs),function(tab){return _c('v-list-item',{key:'tab-'+tab.value},[_c('v-list-item-title',{staticClass:"pointer font-weight-bold  text--lighten-1",class:{'primary--text': _vm.openTab === tab.value, 'grey--text': _vm.openTab !== tab.value},on:{"click":function($event){return _vm.changeTab(tab)}}},[_vm._v(_vm._s(tab.title)+" ")])],1)}),1),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"align-center justify-space-between"},[_c('router-link',{staticClass:"v-list-item",attrs:{"to":{ name: 'opportunity-create' }}},[_c('v-list-item-title',{staticClass:"d-flex align-center text-body-2"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")]),_vm._v(" Add new Opportunity ")],1)],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('common.search'),"single-line":"","hide-details":""},on:{"change":function($event){return _vm.getList()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-data-table',{staticClass:"rounded-b-lg",attrs:{"options":_vm.table.options,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.table.pagination.defaultPageSize,"loading":_vm.table.loading,"server-items-length":_vm.table.total,"footer-props":{
        itemsPerPageOptions: _vm.table.pagination.pageSizeOptions,
      }},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.owners",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(item.owners.map(function (item) { return item.name; }).join(', '))+" ")])]}},{key:"item.status_id",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:item.status_id === 1 ? 'open' : 'closed'},[_vm._v(" "+_vm._s(item.status_id === 1 ? 'Open' : 'Closed')+" ")])]}},{key:"item.investor_count",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'OpportunityInvestors', params: {id:item.id}}}},[_vm._v(" "+_vm._s(item.investor_count)+" ")])]}},{key:"item.share_amount",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.share_amount,false)))])]}},{key:"item.target",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.target,false)))])]}},{key:"item.number_of_shares",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.number_of_shares,false)))])]}},{key:"item.raised_amount",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.raised_amount,false)))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between"},[(item.status_id !== 1)?_c('a',{staticClass:"open mr-2 text-decoration-underline",on:{"click":function($event){return _vm.status(item.id, 1)}}},[_vm._v("Activate")]):_c('a',{staticClass:"closed  mr-2 text-decoration-underline",on:{"click":function($event){return _vm.askCloseStatus(item)}}},[_vm._v("Deactivate")]),_c('a',{staticClass:"mr-2",on:{"click":function($event){return _vm.edit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)])]}}])}),_c('confirm-close-modal',{ref:"close_modal",attrs:{"title":_vm.$t('opportunities.ask_close')},on:{"confirm":_vm.confirmClose}}),_c('v-pagination',{attrs:{"length":_vm.table.pages},on:{"input":_vm.onPageChange},model:{value:(_vm.table.options.page),callback:function ($$v) {_vm.$set(_vm.table.options, "page", $$v)},expression:"table.options.page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }