export const STORE_NAME = process.env.VUE_APP_NAME.toLowerCase().replace(/[^a-z0-9-_]+/ig, '')
export const MAIN_ROUTE = 'home'

export const ROLE_ADMIN = 'admin'
export const ROLE_PARTNER = 'partner'
export const ROLE_MEMBER = 'member'

export const TIME_FORMAT = 'h:mm A'
export const TIME_VALUE_FORMAT = 'HH:mm:00'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_VALUE_FORMAT = 'YYYY-MM-DD'

export const IMAGE_ACCEPT = '.jpg,.jpeg,.png'
export const IMAGE_SIZE_MB = 5
export const IMAGE_SIZE = IMAGE_SIZE_MB * 1024 * 1024
