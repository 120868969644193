<template>
  <OpportunityForm/>
</template>

<script>
import OpportunityForm from "./OpportunityForm"
export default {
  components: {
    OpportunityForm
  },
}

</script>
<style lang="sass"></style>
