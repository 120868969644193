<template>
  <v-container>
    <h2 class="font-weight-bold mb-4">{{ $t('opportunities_invest.summary') }}</h2>
    <div class="opportunity-card" v-if="invest">
      <v-card>
        <v-card-text>
          <div class="d-flex align-center flex-wrap ">
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.request_number') }}</label>
              <span class="black--text font-weight-bold">{{ invest.id }}</span>
            </div>
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.invested_amount') }}</label>
              <span class="black--text font-weight-bold"> {{ invest.amount | toCurrency }}</span>
            </div>
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.transaction_fee') }}</label>
              <span class="black--text font-weight-bold ">{{ 0 | toCurrency }}</span>
            </div>
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.amount_to_pay') }}</label>
              <span class="black--text font-weight-bold"> {{ invest.amount | toCurrency }}</span>
            </div>
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.date') }}</label>
              <span class="black--text font-weight-bold">{{ invest.created_date }}</span>
            </div>
            <div class="mr-5">
              <label class="c-gray d-block text-uppercase">{{ $t('opportunities_invest.time') }}</label>
              <span class="black--text font-weight-bold">{{ invest.created_time }}</span>
            </div>
            <div>
              <v-checkbox :label="$t('opportunities_invest.use_wallet_balance')" v-model="wallet"
                          :disabled="!invest.can_wallet"
                          :error="hasError('wallet')"
                          :error-messages="getErrors('wallet')"
              ></v-checkbox>
            </div>
          </div>

          <v-radio-group
              v-if="!wallet"
              v-model="payment"
              row
              class="black--text mt-5"
          >
            <v-radio
                label="Credit Card"
                value="credit"
            ></v-radio>
            <v-radio
                label="SADAD"
                value="sadad"
                disabled
            ></v-radio>
            <v-radio
                label="STC Pay"
                value="stc"
                disabled
            ></v-radio>
            <v-radio
                label="Mada"
                value="mada"
            ></v-radio>
          </v-radio-group>

          <payment-logos></payment-logos>
        </v-card-text>
      </v-card>
    </div>
    <v-row>
      <v-col lg="5" cols="12">
        <div class="opportunity-card my-5" v-if="!wallet && payment === 'credit'">
          <v-card>
            <v-card-text>
              <v-form id="form_1">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="card.number"
                        outlined
                        label="Card Number"
                        placeholder="xxxx xxxx xxxx xxxx"
                        data-paylib="number"
                        inputmode="numeric" pattern="[0-9\s]{13,19}" autocomplete="cc-number" maxlength="19"
                        :error="hasError('card_details.number')"
                        :error-messages="getErrors('card_details.number')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                        outlined
                        v-model="card.cardholder"
                        label="Name of Card Holder"
                        placeholder="Name Surname"
                        :error="hasError('card_details.cardholder')"
                        :error-messages="getErrors('card_details.cardholder')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                        v-model="card.expire_at"
                        outlined
                        label="Exp. At"
                        placeholder="MM/YY"
                        :error="hasError('card_details.expire_at')"
                        :error-messages="getErrors('card_details.expire_at')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                        v-model="card.cvc"
                        outlined
                        label="Security Code"
                        type="password"
                        placeholder="CVC"
                        :error="hasError('card_details.cvc')"
                        :error-messages="getErrors('card_details.cvc')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <div id="paymentErrors">

              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <div class="mt-5" v-if="invest">
      <router-link :to="{name:'ViewOpportunity', params: {id: invest.opportunity_id}}" class="btn btn__cancel mr-4">
        {{ $t('common.back') }}
      </router-link>
      <a href="#" @click.prevent="submit" class="btn mr-4">
        {{ $t('common.proceed')}}
      </a>
      <a :href="link" v-if="link" class="btn btn-success">
        {{ $t('opportunities_invest.pay')}}
      </a>
      <div v-if="hasError('opportunity')" class="label d-inline-block text--error error--text">
        {{ getError('opportunity')}}
      </div>
    </div>
  </v-container>
</template>

<script>

import hasApiValidation from "@/mixins/hasApiValidation";
import PaymentLogos from "@/components/PaymentLogos";

export default {
  name: "OpportunityInvest",
  components: {PaymentLogos},
  props: ['id'],
  mixins: [hasApiValidation],
  data() {
    return {
      opportunity: null,
      loading: true,
      payment: 'credit',
      link: null,
      wallet: false,
      invest: null,
      card: {
        number: '',
        expire_at: '',
        cardholder: '',
        cvc: '',
      }
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http.get('/invests/' + this.id).then(({data}) => {
        this.invest = data.data;
      }).catch(({response}) => {
        if (response && response.data && response.data.message) {
          this.$noty.error(response.data.message);
        }
        this.$router.push({name: 'ViewOpportunity', params: {id: response.data.opportunity_id}})
      }).finally(() => {
        this.loading = false;
      });
    },
    submit() {
      this.setErrors({})
      let data = {
        wallet: this.wallet,
      }
      switch (this.payment) {
        case 'credit':
          data.payment = 'credit';
          data.card_details = Object.assign({}, this.card)
          break;
      }
      this.$http.post('/invests/' + this.id, data).then(({data}) => {
        this.setErrors({})
        if (this.wallet === true) {
          this.$router.push({name: 'ViewOpportunity', params: {id: this.invest.opportunity_id}});
        } else {
          this.link = data.redirect_url;
        }
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  computed: {}
}
</script>

<style scoped lang="scss">

.opportunity-card {
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 10px;
  overflow: hidden;
  color: #0d0b0b;
}

.opportunity-media {
  width: 100%;
  height: 120px;
  position: relative;
  background: var(--darkGreen);
  display: flex;
  align-items: center;
  justify-content: center;
}

.opportunity-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.opportunity-target {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 35px;
  /* text-align: center; */
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  /* background: red; */
  line-height: 1.1;
  border-bottom-right-radius: 10px;
}

.border-right {
  border-right: 1px solid gray;
}

.opportunity-details {
  padding: 20px;

  label {
    font-size: 0.8rem;
  }

  .v-btn {
    height: 40px !important;
    text-transform: none !important;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    background: #88dec7 !important;
    color: white !important;
    text-transform: none !important;
  }
}
</style>