<template>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="'800px'"
        @click:outside="outsideClose"
    >
      <v-card>
        <v-col>
          <v-card-title>
          <span class="text-h5 font-weight-bold">{{media.title}}</span>
          <v-spacer></v-spacer>
          <font-awesome-icon @click="close()" :icon="['fa', 'times']" />
        </v-card-title>
        </v-col>
        <v-card-text class="text-default" v-if="media.type">
          <template v-if="media.type === 'doc'">
            <a :href="media.url" target="_blank">Download</a>
          </template>
          <template v-if="media.type === 'image'">
            <v-img
                contain
                height="300"
                :src="media.url"
            ></v-img>
          </template>
          <template v-if="media.type === 'video'">
            <video :src="media.url" controls style="width: 100%"></video>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script >
export default {
  name: "MediaModal",
  data: () => ({
    dialog: false,
    media: {},
    send: '',
  }),

  methods: {
    open (e) {
      this.dialog = true;
      this.media = e;
      this.$emit('open', e)
    },

    outsideClose() {
      if (!this.preventClose) {
        this.close();
      }
    },

    close (e) {
      this.dialog = false
      this.media = {}
      this.$emit('close', e)
    },
  },
}

</script>
<style>
 .text-default{
   color: black!important;
   font-size: 1rem;
 }
</style>