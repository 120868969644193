<template>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="'800px'"
        @click:outside="outsideClose"
    >
      <v-card>
        <v-col>
          <v-card-title>
          <span class="text-h5 font-weight-bold">Terms & Conditions</span>
          <v-spacer></v-spacer>
          <font-awesome-icon @click="close()" :icon="['fa', 'times']" />
        </v-card-title>
        </v-col>
        <v-card-text class="text-default">
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper suscipit, posuere a, pede.</p>
          <p>Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis.</p>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script >
export default {
  name: "TermsAndConditionsModal",
  data: () => ({
    dialog: false,
    email: '',
    send: '',
  }),

  methods: {
    open (e) {
      this.dialog = true;
      this.$emit('open', e)
    },

    outsideClose() {
      if (!this.preventClose) {
        this.close();
      }
    },

    close (e) {
      this.dialog = false
      this.$emit('close', e)
    },
  },
}

</script>
<style>
 .text-default{
   color: black!important;
   font-size: 1rem;
 }
</style>