<template>
    <v-dialog
        v-model="dialog"
        persistent
        :max-width="maxWidth + 'px'"
        @click:outside="outsideClose"
    >
      <v-card>
        <v-col>
          <v-card-title>
          <span class="text-h5 font-weight-bold">{{title}}</span>
          <v-spacer></v-spacer>
          <font-awesome-icon @click="close()" :icon="['fa', 'times']" />
        </v-card-title>
        </v-col>
        <v-card-text v-if="description">
            {{description}}
        </v-card-text>
        <v-card-text>
          <v-form @submit.prevent="signUp">
            <template v-if="!code_requested">
                  <vue-tel-input-vuetify
                      v-model="form.phone"
                     :error="hasError('phone')"
                     :error-messages="getErrors('phone')"
                     label="Mobile Number"
                     type="phone"
                     defaultCountry="us"
                     mode="international"
                     outlined
                  ></vue-tel-input-vuetify>
            </template>
            <template v-else>
              <span class="font-weight-bold mb-2 d-block">We have send your a One-Time Password to your phone number.<br>Please enter a code below</span>
              <v-text-field
                  outlined
                  v-model="form.code"
                  label="Enter a Code"
                  placeholder="xxxx"
                  :error="hasError('code')"
                  :error-messages="getErrors('code')"
                  type="text"
                  required
              ></v-text-field>
            </template>
            <v-row class="my-0">
              <v-col class="py-0">
                <button type="submit" class="btn">
                  {{$t('common.save')}}
                </button>
                <a href="#" @click.prevent="send" v-if="code_requested" class="d-block mt-2">{{$t('common.resend')}} </a>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script >
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "PhoneNumberModal",
  data: () => ({
    dialog: false,
    email: '',
    form: {
      national_id: '',
      birthdate: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      code: ''
    },
    code_requested: false,
  }),

  mixins: [hasApiValidation],
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 800
    }
  },

  methods: {
    open (e) {
      this.dialog = true;
      this.$emit('open', e)
    },

    outsideClose() {
      if (!this.preventClose) {
        this.close();
      }
    },

    close (e) {
      this.dialog = false
      this.$emit('close', e)

      this.form =  {
            email: '',
            code: ''
      }
      this.code_requested =  false;
      this.setErrors({});
    },

    send() {
      this.$http.post('/profile/otp', this.form).then(() => {
        this.code_requested = true;
        this.setErrors({});
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      });
    },
    signUp() {
      if (!this.code_requested) {
        this.send();
      } else {
        this.$http.post('/profile/phone', this.form).then(() => {
          this.code_requested = true;
          this.close();
          this.$emit('updated')
          this.$noty.success(this.$t('common.saved'))
          this.setErrors({});
        }).catch(({response}) => {
          this.setErrors(response.data.errors)
        })
      }
    },
    login() {
      this.close();
      this.$emit('login')
    }
  },
}

</script>
