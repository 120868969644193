export default {
    hello: 'Hello World',
    auth: {
        new_password: 'New Password',
        confirm_password: 'Confirm Password',
        dont_have_account_question: 'Don’t have an account?',
        sign_up: 'Sign Up',
        enter_code: 'Enter a Code',
        email_verified: 'Mail Verified',
        email: 'Email Address',
        login: 'Log in',
        resend_verification: 'Resend Verification',
        mail_send: 'Mail Send',
        forgot_password: 'Forgot password',
        sent_otp: 'We have send your a One-Time Password to your phone number.',
        enter_code_below: 'Enter a Code below',
        reset_password: 'Reset a Password',
        already_have_question: 'Already have an account ?',
        verify_your_email: 'Verify Your Email',
        verify_your_email_desc: 'An email verification has been sent to your inbox. Please check and verify your email to complete your activation.',
        logout: 'Logout',
        mail_verified: 'Mail Verified'
    },
    pages: {
        home: 'Home',
        dashboard: 'Dashboard',
        opportunities: 'Opportunities',
        profile: 'Profile',
        contact_us: 'Contact Us',
        menu_item: 'Menu Item',
        add_funds: 'Add Funds',
        wallet: 'Wallet',
        transactions: 'Transactions',
        company_settings: 'Company Settings',
        users: 'Users',
        privacy_policy: 'Privacy Policy',
        terms_conditions: 'Terms and Conditions',
    },
    home: {
        title: 'Find reliable opportunities where to invest',
        learn_more: 'Learn more',
        copyright: "Copyright by nama company with register number 100101010 beer salman 3575-754",
        investment_opportunities: 'Investment Opportunities',
        investment_opportunities_text: 'Through our crowdfunding platform, investors can review investment offerings, sign legal documents online, and transfer investment funds. Invest with us as little as SAR 1,000. Our investment offerings are tied to special Startups. Gain insider access to these high quality opportunities that were diffcult to access online.',
        see_all: 'See All',
        what_is_emtalk: 'What is EMTALK?',
        what_is_emtalks: [
            'EMTALK is a platform owned by Namaa for Financial Technology and works on collective investment in real estate assets, and aims to achieve appropriate returns for investors by investing in real estate opportunities in a technical way. So that real estate opportunities are presented on the platform, and all the information that It clarifies the case of the real estate opportunity and the information that achieves the denial of ignorance, then the investment is made electronically, whereby the investor has a specific ownership in the investment units in the opportunity.',
        ],
        why_marks: [
            {
                title: 'Reliable Platform',
                text: 'More than $3 billion in completed SFR transactions in less than six years',
                icons: ['shield', 'check white-icon']
            },
            {
                title: 'Guarantee',
                text: 'Real estate investment is one of the safe and reliable investments',
                icons: ['lock-alt']
            },
            {
                title: 'Technology Advantage',
                text: 'Make investment decisions using insights, proprietary data and technology',
                icons: ['laptop']
            },
            {
                title: 'Real Estate Data',
                text: 'Research individual properties and get in touch with accurate data.',
                icons: ['search-location']
            },
        ],
        faq: 'FAQ',
        faq_items: [
            {
                title: 'How to invest in the EMTALK platform ? ',
                text: 'In simple steps, you can recharge your balance on the platform and choose the real estate opportunity and invest in it'
            },
            {
                title: 'Are profits guaranteed?',
                text: 'There is no guarantee on profits and the investment may result in a profit or a loss'
            },
        ],
        contact_us: 'Contact Us',
        sign_up: 'Sign Up',
        why_we: 'Why We?'
    },
    common: {
        back: 'Back',
        search: 'Search',
        save: 'Save',
        saved: 'Saved',
        cancel: 'Cancel',
        send: 'Send',
        resend: 'Resend',
        submit: 'Submit',
        proceed: 'Proceed',
        update: 'Update',
        export: 'Export',
        confirm: 'Confirm',
    },
    opportunities_export: {
        address: 'Address',
        export_details: 'Export Details',
        title_deeds: 'Title Deeds',
        issue_date: 'Issue Date',
    },

    opportunities: {
        target: 'Target',
        raised_amount: 'Raised Amount',
        equitiy_offered: 'Equity Offered',
        current_investors: 'Current Investors',
        minimum_target: 'Minimum Target',
        maximum_target: 'Maximum Target',
        minimum_equitiy_offered: 'Minimum Equitiy offered',
        maximum_equitiy_offered: 'Maximum Equitiy offered',
        val_pre_money: 'Valuation pre-money',
        val_post_money: 'Valuation post-money',
        number_of_shares: 'Number of shares',
        invest: 'Invest',
        owners: 'Owners',
        open_period: 'Opening Period',
        share_amount: 'Share Amount',
        investment_ceiling: 'Investment Ceiling',
        roi: 'Project ROI %',
        type: 'Type',
        purpose: 'Purpose',
        area: 'Area',
        designer: 'Designer',
        developer: 'Developer',
        project_plan: 'Project Plan',
        stakeholder: 'Stakeholder',
        location: 'Location',
        media: 'Media',
        all: 'All',
        owned: 'Owned Opportunities',
        invested: 'Invested Opportunities',
        investors_number: '# INVESTORS',
        status: 'Status',
        opening_date: 'Opening Date',
        close_date: 'Close Date',
        id: 'ID',
        title: 'Title',
        choose_location: 'Choose Location',
        type_address: 'Type address of the building or manually select on map',
        period_date: 'Period Date',
        real_estate_info: 'Real Estate Information',
        search_address: 'Search address',
        description: 'Description',
        total_shares: 'Total Shares',
        target_shares: 'Target Shares',
        evaluation: 'Evaluation',
        equitity_offered: 'Equity Offered',
        file_validation: 'Media shouldn’t be more than 5 MB',
        recommend_investment: 'We recommend to not change the values since their are an investment occurred',
        ask_update: 'Do you want to update opportunity?',
        ask_close: 'Are sure you want to deactivate this opportunity?',
        values: 'Values',
        ask_update_values: 'Do you want to update values?',
        minimum_shares: 'Minimum shares',
    },
    opportunities_invest: {
        summary: 'Transaction Summary',
        request_number: 'Requested Number',
        invested_amount: 'Invested Amount',
        transaction_fee: 'Transaction Fee',
        amount_to_pay: 'Amount to pay',
        date: 'Date',
        time: 'Time',
        use_wallet_balance: 'Use wallet balance',
        amount: 'Amount',
        pay: 'Pay',
        retry: 'Retry',
        payment_success: 'Payment Success',
        payment_fail: 'Payment Fail (reason: {reason})',
    },
    wallet: {
        amount_to_add: "Amount to add",
        current_balance: 'Current Balance',
        view_transaction_history: 'View Transaction History',
        returned: 'Returned',
        return_started: 'Return Start',
        pay: 'Pay',
        add_funds: 'Add Funds',
        transaction_id: 'Transaction ID',
    },
    profile: {
        info: 'Confidential Parameters do not appear to investors',
        title: 'Investor Profile',
        admin_title: 'Admin Profile',
        first_name: 'First Name',
        last_name: 'Last Name',
        national_id: 'National ID',
        phone: 'Mobile Number',
        email: 'Email Address',
        nationality: 'Nationality',
        income: 'Monthly Income',
        birthdate: 'Date of birth',
        address_info: 'Address Information',
        city: 'City',
        district: 'District',
        street: 'Street',
        po_box: 'P.O. Box',
        postal_code: 'Postal Code',
        bank_info: 'Bank Account Information',
        bank_holder_name: 'Bank Holder Name',
        holder_name: 'Holder Name',
        iban: 'IBAN #',
        iban_confirmation: 'Confirm IBAN #',
        user_acc_info: 'User Account Information',
        attach_media: 'Attach media',
        password: 'Password',
        email_need_verify: 'Email changed, please verify it. (Resend)',
        download_investment_agreement: 'Download Agreement',
        profile_photo: 'Profile Photo'
    },
    users: {
        id: 'ID',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        phone: 'Mobile #',
    },
    dashboard: {
        wallet_funds: 'Wallet Funds',
        raise_funds: 'Raise Funds',
        expected_returns: 'Expected Returns',
        current_risk_tolerance: 'Current Risk Tolerance',
        portfolio_percentage: 'Percentage of the portfolio',
        investment_details: 'Investment Details',
    },
    contact_us: {
        contact: 'Contact',
        name: 'Name',
        subject: 'Subject',
        email: 'Email',
        phone: 'Phone Number',
        message: 'Message',
        send: 'Send',
        success_message: 'Message received, we will contact you'
    }
}
