import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import i18n from '@/core/plugins/i18n/'
//import * as middleware from './middleware'

Vue.use(Router)


const parseRoutes = (array) => {
    return array.map(item => {
        const route = { ...item }
        if (!route.meta) {
            route.meta = {}
        }
        if (route.children) {
            route.children = parseRoutes(route.children)
        }
        if (route.component) {
            route.meta = { ...route.meta, ...(route.component.meta || {}) }
        }
        return route
    })
}


Vue.router = new Router({
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    linkExactActiveClass: '',
    mode: 'history',
    routes: parseRoutes(routes)
})


Vue.router.beforeEach((to, from, next) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    const meta = Object.assign({}, ...(to.matched.map(item => item.meta) || {}))
//    const mdw = meta.middleware

    // if (mdw) {
    //     for (const mKey in mdw) {
    //         if (middleware[mKey](next, mdw[mKey]) !== true) return
    //     }
    // }

    document.title = (meta.title ? `${i18n.t(meta.title)} - ` : '') + process.env.VUE_APP_NAME
    next()
})


export default Vue.router
