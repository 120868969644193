<template>
  <router-link :to="{name:'home'}" class="d-flex align-center">
    <div class="logo">
      <img :src="logo">
    </div>
  </router-link>
</template>


<script>
export default {
  name:"Logo",

  data: () => ({
    logo: '/assets/logo.png'

  }),

  methods: {

  },

  created () {

  }
}
</script>
<style lang="sass">@import "style"</style>
