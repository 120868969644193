<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation>
    <v-card class="pb-10">
      <v-card-title>
        {{ isEdit ? 'Edit' : 'Add new' }}
        Opportunity
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-autocomplete
                :label="$t('opportunities.owners')"
                type="text"
                item-text="text"
                item-value="id"
                v-model="form.owners"
                :items="owners"
                multiple
                outlined
                required
                :error="hasError('owners')"
                :error-messages="getErrors('owners')"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
                ref="period_date_menu"
                v-model="period_date_menu"
                :close-on-content-click="false"
                :return-value.sync="opportunity_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="'from '+(form.date_from ? form.date_from : '--') + ' to ' + (form.date_to? form.date_to : '--')"
                    :label="$t('opportunities.period_date')"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :error="hasError('date_from') ||hasError('date_to')"
                    :error-messages="getErrors('date_from').concat(getErrors('date_to'))"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="opportunity_date" no-title scrollable
                  range
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="period_date_menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.period_date_menu.save(opportunity_date)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.stakeholder"
                :label="$t('opportunities.stakeholder')"
                outlined
                required
                :error="hasError('stakeholder')"
                :error-messages="getErrors('stakeholder')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="my-15">
      <v-card-title class="d-flex justify-space-between align-center">
        <span>{{ $t('opportunities.values') }}</span>
        <v-btn color="primary" small v-if="isEdit" @click="openEditValues()">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.share_amount"
                :label="$t('opportunities.share_amount')"
                type="number"
                outlined
                required
                :error="hasError('share_amount')"
                :error-messages="getErrors('share_amount')"
                :readonly="isEdit"
                :disabled="isEdit"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.target"
                :label="$t('opportunities.target')"
                type="number"
                outlined
                required
                :error="hasError('target')"
                :error-messages="getErrors('target')"
                :readonly="isEdit"
                :disabled="isEdit"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.evaluation"
                :label="$t('opportunities.evaluation')"
                type="number"
                outlined
                required
                :error="hasError('evaluation')"
                :error-messages="getErrors('evaluation')"
                :readonly="isEdit"
                :disabled="isEdit"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.target_shares"
                :label="$t('opportunities.target_shares')"
                type="number"
                outlined
                required
                :error="hasError('target_shares')"
                :error-messages="getErrors('target_shares')"
                :readonly="isEdit"
                :disabled="isEdit"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.total_shares"
                :label="$t('opportunities.total_shares')"
                readonly
                type="number"
                outlined
                required
                :error="hasError('total_shares')"
                :error-messages="getErrors('total_shares')"
                :disabled="isEdit"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.equitity_offered"
                :label="$t('opportunities.equitity_offered') + ' %'"
                type="number"
                readonly
                outlined
                required
                :error="hasError('equitity_offered')"
                :error-messages="getErrors('equitity_offered')"
                :disabled="isEdit"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.investment_ceiling"
                :label="$t('opportunities.investment_ceiling')"
                type="number"
                outlined
                required
                :error="hasError('investment_ceiling')"
                :error-messages="getErrors('investment_ceiling')"
                :readonly="isEdit"
                :disabled="isEdit"
            ></v-text-field>
          </v-col>

          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.roi"
                :label="$t('opportunities.roi')"
                type="number"
                outlined
                required
                :error="hasError('roi')"
                :error-messages="getErrors('roi')"
                :readonly="isEdit"
                :disabled="isEdit"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.minimum_shares"
                :label="$t('opportunities.minimum_shares')"
                type="number"
                outlined
                required
                :error="hasError('minimum_shares')"
                :error-messages="getErrors('minimum_shares')"
                :readonly="isEdit"
                :disabled="isEdit"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="my-15">
      <v-card-title>{{ $t('opportunities.real_estate_info') }}</v-card-title>
      <v-container fluid>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-select
                :label="$t('opportunities.type')"
                item-text="key"
                item-value="value"
                v-model="form.details.type"
                :items="types"
                outlined
                required
                :error="hasError('details.type')"
                :error-messages="getErrors('details.type')"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
                :label="$t('opportunities.purpose')"
                item-text="key"
                item-value="value"
                v-model="form.details.purpose"
                :items="purposes"
                outlined
                required
                :error="hasError('details.purpose')"
                :error-messages="getErrors('details.purpose')"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.details.area"
                :label="$t('opportunities.area')"
                type="text"
                outlined
                required
                :error="hasError('details.area')"
                :error-messages="getErrors('details.area')"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.details.designer"
                :label="$t('opportunities.designer')"
                type="text"
                outlined
                required
                :error="hasError('details.designer')"
                :error-messages="getErrors('details.designer')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.details.developer"
                :label="$t('opportunities.developer')"
                outlined
                type="text"
                required
                :error="hasError('details.developer')"
                :error-messages="getErrors('details.developer')"
            ></v-text-field>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-menu
                ref="period_date_details_menu"
                v-model="period_date_details_menu"
                :close-on-content-click="false"
                :return-value.sync="opportunity_project_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="'from '+(form.details.project_date_from ? form.details.project_date_from : '--') + ' to ' + (form.details.project_date_to? form.details.project_date_to : '--')"

                    :label="$t('opportunities.period_date')"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :error="hasError('details.project_date_from')||hasError('details.project_date_to')"
                    :error-messages="getErrors('details.project_date_from').concat(getErrors('details.project_date_to'))"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="opportunity_project_date" no-title scrollable
                  range
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="period_date_details_menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.period_date_details_menu.save(opportunity_project_date)">OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >

            <div class="file_uploads d-flex flex-nowrap align-start">
              <v-file-input
                  v-model="file_upload"
                  :label="$t('profile.attach_media')"
                  outlined
                  :error="hasError('file')"
                  :error-messages="fileValidation"
              />
              <button type="button" class="btn ml-2 py-4" v-if="file_upload" @click="upload_media">
                <v-icon dark>mdi-cloud-upload</v-icon>
              </button>
              <br>
            </div>
            <v-row class="mb-3">
              <v-col cols="3" v-for="media in append_medias" :key="media.id">
                <div class="media-preview" :class="{'media_deleting': media_deleting = media.id}">
                  <a class="media_delete" @click.prevent="deleteMedia(media.id)">
                    <v-icon>mdi-close-circle</v-icon>
                  </a>
                  <a :href="media.url" target="_blank" :title="media.title" class="link">
                    <v-img
                        v-if="media.type === 'image'"
                        contain
                        :src="media.type === 'image'?  media.url : '/assets/images/'+media.type+'.png'"
                    ></v-img>
                    <v-icon v-if="media.type === 'video'" class="typ-ico">mdi-play-circle</v-icon>
                    <v-icon v-if="media.type === 'doc'" class="typ-ico">mdi-file-document</v-icon>
                  </a>
                </div>
              </v-col>
            </v-row>

            <div v-if="location" class="d-flex align-center flex-wrap">
              <span class="font-weight-medium mr-4">{{ location }}</span>
              <a href="#" @click.prevent.stop="openMap()" class=" black--text">
                <v-icon color="black">
                  mdi-map-marker
                </v-icon>
                <span class="text-decoration-underline ">Change Location</span>
              </a>
            </div>
            <div v-else>
              <div class="d-flex align-center pointer mb-3" @click.stop="openMap()">
                <v-icon color="black">mdi-map-marker</v-icon>
                <span class="text-decoration-underline ">Add Location</span>
              </div>
            </div>
            <location-modal :title="$t('opportunities.choose_location')" :description="$t('opportunities.type_address')"
                            ref="location_map" @done="updateLocation"></location-modal>
          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="form.title"
                :label="$t('opportunities.title')"
                outlined
                :error="hasError('title')"
                :error-messages="getErrors('title')"
                required
            ></v-text-field>
            <v-textarea
                v-model="form.details.description"
                :label="$t('opportunities.description')"
                outlined
                :error="hasError('details.description')"
                :error-messages="getErrors('details.description')"
                required
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <GmapMap style="display: none;" ref="g_map2" :center="{lat:10, lng:10}"/>
    </v-card>
    <v-col class="py-0">
      <a href="#" @click="submit" class="btn">
        {{ isEdit ? 'Update' : 'Add' }}
      </a>
      <router-link :to="{name:'opportunity-list'}" href="#" class="btn btn__cancel ml-4">
        Cancel
      </router-link>
    </v-col>
    <confirm-edit-modal ref="confirm_edit_modal" :title="$t('opportunities.ask_update')"
                        @submit="submitEdit"></confirm-edit-modal>
    <values-modal ref="values_modal" :title="$t('opportunities.values')" @ask_save="$refs.confirm_values_modal.open()"
                  @saved="updateValues"></values-modal>
    <confirm-edit-values-modal ref="confirm_values_modal" :title="$t('opportunities.ask_update_values')"
                               @submit="$refs.values_modal.saveValues()"></confirm-edit-values-modal>
  </v-form>
</template>

<script>
// import ApiService from "../../api";
// import ApiServiceUsers from "../../../users/api"
import {gmapApi} from 'vue2-google-maps'
import {mapState} from 'vuex'
import hasApiValidation from "@/mixins/hasApiValidation";
import LocationModal from "@/modules/opportunities/LocationModal";
import ConfirmEditModal from "@/modules/opportunities/ConfirmEditModal";
import ValuesModal from "@/modules/opportunities/ValuesModal";
import ConfirmEditValuesModal from "@/modules/opportunities/ConfirmEditValuesModal";

export default {
  data: () => ({
    file_upload: null,
    append_medias: [],
    media_deleting: null,
    location: null,
    opportunity_date: [],
    opportunity_project_date: [],
    period_date_menu: false,
    period_date_details_menu: false,
    owners: [],
    isButton: false,
    form: {
      date_from: null,
      date_to: null,
      share_amount: null,
      target: null,
      investment_ceiling: null,
      roi: null,
      total_shares: null,
      target_shares: null,
      evaluation: null,
      equitity_offered: null,
      minimum_shares: null,
      // relation
      owners: [],
      stakeholder: '',
      // details
      details: {
        // enums
        purpose: null,
        type: null,
        //
        area: null,
        designer: null,
        developer: null,
        project_date_from: null,
        project_date_to: null,
        latitude: null,
        longitude: null,
        description: null
      },
      media: []
    }
  }),
  mixins: [hasApiValidation],
  watch: {
    'form.evaluation': function (val) {
      if (val && this.form.share_amount) {
        this.form.total_shares = val / this.form.share_amount;
      }
      if (val && this.form.target) {
        this.form.equitity_offered = Number(this.form.target / val * 100).toFixed(2);
      }
    },
    'form.target': function (val) {
      if (val && this.form.share_amount) {
        this.form.target_shares = val / this.form.share_amount;
      }
      if (val && this.form.evaluation) {
        this.form.equitity_offered = Number(val / this.form.evaluation * 100).toFixed(2);
      }
    },
    'form.share_amount': function (val) {
      if (val && this.form.target) {
        this.form.target_shares = this.form.target / val;
      }
      if (val && this.form.evaluation) {
        this.form.total_shares = this.form.evaluation / val;
      }
    },
    file_upload: function () {
      delete this.errors.file
    },
    formData: function (formData) {
      // if (_.isEmpty(formData)) {
      //     this.handleClearForm()
      // }
      this.initForm(formData)
    },
    // Todo: refactor on setter
    opportunity_date: function (data) {
      this.form.date_from = data[0]
      this.form.date_to = data[1]
    },
    opportunity_project_date: function (data) {
      this.form.details.project_date_from = data[0]
      this.form.details.project_date_to = data[1]
    },
  },

  props: {
    formData: {
      type: Object,
      default: null
    }
  },

  components: {ConfirmEditValuesModal, ValuesModal, ConfirmEditModal, LocationModal},
  computed: {
    fileValidation() {
      if (!this.file_upload) {
        return [];
      }
      let errors = this.getErrors('file');
      if (this.file_upload.size > 5000000) {
        errors.push(this.$t('opportunities.file_validation'));
      }
      return errors;
    },
    google: gmapApi,
    ...mapState({
      purposes: state => state.app.options.opportunity_purposes ? Object.entries(state.app.options.opportunity_purposes).map(([key, value]) => ({
        key,
        value
      })) : [],
      types: state => state.app.options.opportunity_types ? Object.entries(state.app.options.opportunity_types).map(([key, value]) => ({
        key,
        value
      })) : []
    }),

    isEdit() {
      return this.formData !== null
    }
  },

  methods: {
    openEditValues() {
      this.$refs.values_modal.open(this.form);
    },
    upload_media() {
      if (!this.file_upload || this.file_upload.size > 5000000) {
        return
      }
      let data = new FormData();
      data.append('file', this.file_upload);
      this.$http.post('/admin/files/upload', data).then(({data}) => {
        this.append_medias.push(data.data)
        this.file_upload = null;
        if (this.errors.file) {
          delete this.errors.file
        }
      }).catch((err) => {
        this.errors = {...this.errors, file: err.response.data.errors.file}
      });
    },
    deleteMedia(id) {
      this.append_medias = this.append_medias.filter(item => item.id !== id);
    },
    openMap() {
      let mark = null
      if (this.form && this.form.details && this.form.details.latitude && this.form.details.longitude) {
        mark = {
          lat: Number(this.form.details.latitude),
          lng: Number(this.form.details.longitude),
        }
      }

      this.$refs.location_map.open(mark);
    },
    initForm(formData) {
      if (formData !== null) {
        this.$set(this, 'form', {...this.form, ...formData})
        this.opportunity_date = [
          formData.date_from,
          formData.date_to,
        ]

        this.opportunity_project_date = [
          formData.details.project_date_from,
          formData.details.project_date_to
        ]
        this.append_medias = formData.media
        if (formData && formData.details && formData.details.latitude && formData.details.longitude) {
          const geocoder = new this.google.maps.Geocoder();
          geocoder
              .geocode({
                location: {
                  lat: Number(formData.details.latitude),
                  lng: Number(formData.details.longitude),
                }
              })
              .then((response) => {
                if (response.results[0]) {
                  this.location = response.results[0].formatted_address
                }
              })
              .catch(() => {
              })
        }
      }
    },
    updateValues(values) {
      Object.assign(this.form, values);
    },
    submitEdit() {
      this.$http.put('/admin/opportunities/' + this.form.id, this.form).then(() => {
        this.$router.push({name: 'opportunity-list'})
      }).catch(({response}) => {
        this.setErrors(response.data.errors);
      });
    },
    submit() {
      this.form.media = this.append_medias.map(item => item.id);
      if (this.isEdit) {
        this.$refs.confirm_edit_modal.open(this.form);
      } else {
        this.$http.post('/admin/opportunities/', this.form).then(() => {
          this.$router.push({name: 'opportunity-list'})
        }).catch(({response}) => {
          this.setErrors(response.data.errors);
        });
        // ApiService.create(formData).then( () => {
        //     this.$router.push({ name: 'opportunity-list' })
        // });
        // ApiService.create(this.form).then( () => {
        //     this.$router.push({ name: 'opportunity-list' })
        // });
      }
    },
    getOwners() {
      this.$http.get('/users/owners').then((data) => {
        this.owners = data.data.data
      });
    },
    getData() {
      this.$http.get('/init').then(({data}) => {
        this.$store.dispatch('app/options', data.options)
      });
    },
    updateLocation(location) {
      this.location = location.name;
      this.form.details.latitude = location.lat;
      this.form.details.longitude = location.lng;
    }
  },

  mounted() {
    this.getOwners()
    this.getData()
    this.initForm(this.formData)
  },

  created() {

  }
}


</script>
<style lang="scss">
.media-preview {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .link {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .typ-ico {
    font-size: 66px;
  }

  .media_delete {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    display: none;
    z-index: 3000;
  }

  .v-image {
    max-height: 100%;
  }

  &.media_deleting .media_delete {
    display: block;
  }
}
</style>
