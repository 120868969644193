import List from "@/view/pages/Opportunities/List";
import View from "@/view/pages/Opportunities/View";

export default [
    {
        path: '/opportunities',
        component: List,
        name:"Opportunities",
        meta: {
            title: 'Opportunities',
            layout: 'Home'
            // icon: '',
            //roles: ['SuperAdmin'],
        }
    },
    {
        path: '/opportunities/:id',
        component: View,
        name:"OpportunityView",
        props: true,
        meta: {
            title: 'Opportunity',
            layout: 'Home'
            // icon: '',
            //roles: ['SuperAdmin'],
        }
    }
]