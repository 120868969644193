<template>
  <div>
    <v-data-table
        :options.sync="table.options"
        :headers="headers"
        :items="items"
        :items-per-page="table.pagination.defaultPageSize"
        :loading="table.loading"
        :server-items-length="table.total"
        class="rounded-b-lg"
        :footer-props="{
          itemsPerPageOptions: table.pagination.pageSizeOptions,
        }"
    >
      <template v-slot:item.owners="{ item }">
        <div>
          {{ item.owners.map(item => item.name).join(', ') }}
        </div>
      </template>
      <template v-slot:item.share_amount="{ item }">
        <span>{{item.share_amount | toCurrency(false)}}</span>
      </template>
      <template v-slot:item.target="{ item }">
        <span>{{item.target | toCurrency(false)}}</span>
      </template>
      <template v-slot:item.raised_amount="{ item }">
        <span>{{item.raised_amount | toCurrency(false)}}</span>
      </template>
    </v-data-table>

    <v-pagination
        v-model="table.options.page"
        :length="table.pages"
        @input="onPageChange"
    >
    </v-pagination>
  </div>
</template>

<script>
import TablePaginationMixin from "@/mixins/tablePagination"
import {mapActions, mapGetters} from "vuex"

export default {
  name: "InvestedOpportunities",
  data: () => ({}),

  mixins: [TablePaginationMixin],
  watch: {
    'table.options': {
      handler() {
        this.getList()
      },
      deep: true,
    },
  },
  methods: {
    getList() {
      this.table.loading = true;
      this.getOpportunities({...this.table.options, type: 'invested'}).then((data) => {
        if (data.meta) {
          this.table.pages = data.meta.last_page;
          this.table.total = data.meta.total;
        }
      }).finally(() => {
        this.table.loading = false;
      });
    },
    onPageChange() {
      this.getList();
    },
    ...mapActions({
      getOpportunities: 'getAdminOpportunities'
    }),
  },

  mounted() {
  },

  computed: {
    headers() {
      return [
        {text: this.$t('opportunities.id'), value: 'id', width: "65px"},
        {text: this.$t('opportunities.title'), value: 'title', width: "65px"},
        {text: this.$t('opportunities.opening_date'), value: 'date_from', width: "120px"},
        {text: this.$t('opportunities.close_date'), value: 'date_to', width: "120px"},
        {text: this.$t('opportunities.owners'), value: 'owners', sortable: false, width: "220px"},
        {text: this.$t('opportunities.number_of_shares'), value: 'my_invests', width: "85px"},
        {text: this.$t('opportunities.share_amount'), value: 'share_amount', width: "85px"},
        {text: this.$t('opportunities.target'), value: 'target', width: "85px"},
        {text: this.$t('opportunities.raised_amount'), value: 'raised_amount', width: "85px"},
        {text: '%', value: 'roi', width: "85px"},

      ]
    },
    ...mapGetters({
      items: 'opportunities'
    }),
  }
}

</script>
<style lang="sass" scoped>

</style>
