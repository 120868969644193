var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"rounded-b-lg",attrs:{"options":_vm.table.options,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.table.pagination.defaultPageSize,"loading":_vm.table.loading,"server-items-length":_vm.table.total,"footer-props":{
        itemsPerPageOptions: _vm.table.pagination.pageSizeOptions,
      }},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.owners",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.owners.map(function (item) { return item.name; }).join(', '))+" ")])]}},{key:"item.share_amount",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.share_amount,false)))])]}},{key:"item.target",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.target,false)))])]}},{key:"item.raised_amount",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toCurrency")(item.raised_amount,false)))])]}}])}),_c('v-pagination',{attrs:{"length":_vm.table.pages},on:{"input":_vm.onPageChange},model:{value:(_vm.table.options.page),callback:function ($$v) {_vm.$set(_vm.table.options, "page", $$v)},expression:"table.options.page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }