import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from './modules/app.module'
import opportunities from './modules/opportunities'

export default new Vuex.Store({
    modules: {
        app,
        opportunities,
    }
})
