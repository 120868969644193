export default {
    data() {
        return {
            errors: {},
        }
    },
    methods: {
        setErrors(errors) {
            this.errors = errors;
        },
        hasError(key) {
            return (this.errors && !!this.errors[key]);
        },
        getError(key) {
            return (this.errors && this.errors[key]) ?
                (typeof this.errors[key] === 'string') ? this.errors[key] : this.errors[key][0]
                : "";
        },
        getErrors(key) {
            return (this.errors && this.errors[key]) ?
                (typeof this.errors[key] === 'string') ? [this.errors[key]]: this.errors[key]
                : [];
        },
        checkState(key) {
            return (this.errors && !!this.errors[key]) ? false : null;
        },
        clearError(key) {
            delete this.errors[key]
        },
        validate() {
            return true;
        },
    },
};
