<template>
  <v-dialog
      v-model="dialog"
      persistent
      :max-width="maxWidth + 'px'"
      @click:outside="outsideClose"
  >
    <v-card>
      <v-col>
        <v-card-title>
          <span class="text-h5 font-weight-bold">{{ title }}</span>
          <v-spacer></v-spacer>
          <font-awesome-icon @click="close()" :icon="['fa', 'times']"/>
        </v-card-title>
      </v-col>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <span class="text-lg " v-if="description">
              {{ description }}
            </span>
          </v-col>
          <v-col cols="6">
            <div id="holder-autocomplete" class="relative"></div>
            <v-autocomplete
                attach="#holder-autocomplete"
                :loading="loading"
                :items="items"
                :search-input.sync="searchedLocation"
                @change="placePin"
                item-text="description"
                item-value="place_id"
                cache-items
                :label="$t('opportunities.search_address')"
                outlined
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <GmapMap
            :options="mapOptions"
            :center="center"
            :zoom="7"
            map-type-id="terrain"
            style="width: 100%; height:500px"
            ref="g_map"
            @click="mapMarker"
        >
          <GmapMarker v-if="marker"
                      :position="marker"
                      :clickable="true"
                      :draggable="true"
                      @click="center=marker"
          />
        </GmapMap>
        <div class="d-flex mt-3">
          {{ location.name }}
          <a class="btn  ml-auto" @click="done">{{ $t('common.save') }}</a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'


export default {
  name: "LocationModal",
  data: () => ({
    dialog: false,
    model: null,
    email: '',
    send: '',
    searchedLocation: '',
    loading: false,
    items: [],
    mapOptions: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false
    },
    center: {
      lat:24.7491615,
      lng: 46.6161086},
    marker: null,
    location: {
      lng: null,
      lat: null,
      name: '',
    },
    api_key: process.env.VUE_APP_GOOGLE_MAP_KEY
  }),

  components: {},

  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 800
    }
  },
  watch: {
    searchedLocation(val) {
      val && this.searchLocation(val)
    },
  },
  computed: {
    google: gmapApi
  },
  methods: {
    searchLocation(val) {
      this.loading = true
      const service = new this.google.maps.places.AutocompleteService();
      service.getQueryPredictions({input: val}, this.displaySuggestions);
    },
    displaySuggestions(predictions) {
      this.items = predictions.map(item => {
        return {
          description: item.description,
          place_id: item.place_id
        }
      })
      this.loading = false;
    },
    placePin(place) {
      const geocoder = new this.google.maps.Geocoder();
      geocoder
          .geocode({placeId: place})
          .then((response) => {
            if (response.results[0]) {
              this.location.name = response.results[0].formatted_address
              this.marker = {
                lat: response.results[0].geometry.location.lat(),
                lng: response.results[0].geometry.location.lng(),
              }
              this.center = {...this.marker}
            }
          })
    },
    getAndSetMarker(marker) {
      const geocoder = new this.google.maps.Geocoder();
      geocoder
          .geocode({location: marker})
          .then((response) => {
            if (response.results[0]) {
              this.location.name = response.results[0].formatted_address
              this.marker = marker;
            }
          })
    },
    mapMarker(event) {
      let marker = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      this.getAndSetMarker(marker);
    },
    open(marker = null) {
      this.dialog = true;
      if (marker) {
        this.getAndSetMarker(marker);
        this.center = {...marker}
      }
      this.$emit('open', {})
    },

    outsideClose() {
      if (!this.preventClose) {
        this.close();
      }
    },

    close(e) {
      this.dialog = false
      this.$emit('close', e)
    },

    done() {
      this.dialog = false
      if (this.marker) {
        Object.assign(this.location, this.marker)
      }
      this.$emit('done', this.location)
    }
  },
}

</script>
