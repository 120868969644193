import Vue from 'vue';
import axios    from 'axios';
import VueAxios from 'vue-axios';
import qs from 'qs';
import i18n from '@/core/plugins/i18n'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use(config => {
  config.headers['Accept-Language'] =  i18n.locale;
  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  return config;
});
axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if(error && error.response && error.response.status){
        switch (error.response.status) {
          case 401:
            console.log(window.location.href)
            if(window.location.href !== (window.location.origin + '/') ){
              window.location.href = "/";
            }
            else {
              return Promise.reject(error);
            }
            break;
          default:
            return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    });


Vue.use(VueAxios, axios);

export default axios;