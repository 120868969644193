<template>
  <v-form @submit.prevent="signUp">
    <template v-if="!code_requested">
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              outlined
              v-model="form.national_id"
              label="National ID"
              :error="hasError('national_id')"
              :error-messages="getErrors('national_id')"
              required
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              outlined
              v-model="form.birthdate"
              label="Date of birth"
              type="date"
              :error="hasError('birthdate')"
              :error-messages="getErrors('birthdate')"
              required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              outlined
              v-model="form.first_name"
              label="First Name"
              type="text"
              :error="hasError('first_name')"
              :error-messages="getErrors('first_name')"
              required
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              outlined
              v-model="form.last_name"
              :error="hasError('last_name')"
              :error-messages="getErrors('last_name')"
              label="Last Name"
              type="text"
              required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              outlined
              v-model="form.email"
              label="Email Address"
              type="email"
              :error="hasError('email')"
              :error-messages="getErrors('email')"
              required
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              outlined
              v-model="form.phone"
              :error="hasError('phone')"
              :error-messages="getErrors('phone')"
              label="Mobile Number"
              type="phone"
              required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              outlined
              v-model="form.password"
              :error="hasError('password')"
              :error-messages="getErrors('password')"
              label="Password"
              type="password"
              required
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
        >
          <v-text-field
              outlined
              v-model="form.password_confirmation"
              label="Confirm Password"
              type="password"
              required
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <span class="font-weight-bold mb-2 d-block">We have send your a One-Time Password to your email address.<br>Please enter a code below</span>
      <v-text-field
          outlined
          v-model="form.code"
          label="Enter a Code"
          placeholder="xxxx"
          :error="hasError('code')"
          :error-messages="getErrors('code')"
          type="text"
          required
      ></v-text-field>
    </template>
    <v-row>
      <v-col class="py-0">
        <button type="submit" class="btn">
          Sign Up
        </button>
        <a href="#" @click.prevent="send" v-if="code_requested" class="d-block mt-2">Resend </a>
        <SignInLink @login="$emit('login')" v-else class="d-block mt-2"/>
      </v-col>
      <v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SignInLink from '@/modules/auth/components/Helpers/SignInLink'
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  mixins: [hasApiValidation],
  data: () => ({
    form: {
      national_id: '',
      birthdate: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      code: ''
    },
    code_requested: false,
  }),

  props: {},

  components: {
    SignInLink
  },

  methods: {
    send() {
      this.$http.post('/auth/register-otp', this.form).then(() => {
        this.code_requested = true;
        this.setErrors({});
      }).catch(({response}) => {
        this.setErrors(response.data.errors)
      });
    },
    signUp() {
      if (!this.code_requested) {
        this.send();
      } else {
        this.$http.post('/auth/register', this.form).then(() => {
          this.code_requested = true;
          this.$emit('registered')

          this.setErrors({});
        }).catch(({response}) => {
          this.setErrors(response.data.errors)
        })
      }
    },
    login() {
      this.$emit('login')
    }
  },
  created() {

  }
}

</script>
<style lang="sass">@import "style"</style>
